import "./panel.css";

export default function Panel({ ...props }) {
  return <section className={"panel bg-glass"+ (props.selected ? " selected" : "")}>{props.children}</section>;
}

export function MetalPanel({ ...props }) {
  return <section className={
    "panel metal textured"
    + (props.selected ? " selected" : "")
    + (props.muted    ? " muted"    : "")
  }>{props.children}</section>;
}

export function PlasticPanel({ ...props }) {
  return <section className="panel plastic">{props.children}</section>;
}

export function StickyNote({ ...props }) {
  return <section className="sticky-note textured">{props.children}</section>;
}
