interface identifiable{
    id: string;
}

export default class EntityCache<T extends identifiable> {
    delay: number;

    cache: {[id: string]: {data: T|null, lastFetch: Date}} = {};
    lastFullFetch: Date|null = null;

    constructor(delay: number = 500){
      this.delay = delay;
    }

    get(id: string): T|null {
      return this.cache[id]?.data ?? null;
    }

    list(): T[]{
      return Object.values(this.cache)
          .map((tuple) => tuple.data)
          .filter((army) => army !== null) as T[];
    }

    isAllFresh(date: Date = new Date()): boolean {
      return this.lastFullFetch !== null 
          && this.lastFullFetch.getTime() > (date.getTime()+this.delay);
    }

    isFresh(id: string, date: Date = new Date()): boolean {
      return (this.cache[id] !== undefined)  && (this.cache[id].lastFetch.getTime() > (date.getTime()+this.delay));
    }
  
    setAll(dataArray: T[]) {
      dataArray.forEach((data) => this.set(data.id, data));
      this.lastFullFetch = new Date();
    }
  
    set(id:string, data: T|null){
      if(data === null) {
        this.cache[id] = {data: null, lastFetch: new Date()};
        return;
      }
      this.cache[id] = {
          data: data, 
          lastFetch: new Date()
      };
      return;
    }
  }