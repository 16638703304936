import AuthService from "shared/services/AuthService/AuthService";
import AbstractSubject from "shared/services/Observer/AbstractSubject";

import http from "utils/http-common";
import NationServiceInterface from "./NationServiceInterface";

const URL = "/game/nations/";

export default class NationService
  extends AbstractSubject
  implements NationServiceInterface
{
  authService: AuthService;

  constructor(authService: AuthService) {
    super();
    this.authService = authService;
  }

  async list() {
    try {
      const response = await http.get(URL, {
        headers: this.authService.authHeader(),
      });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        this.authService.logout();
        throw error;
      }
    }
  }

  async get(id: string) {
    try {
      const response = await http.get(URL + id, {
        headers: this.authService.authHeader(),
      });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        this.authService.logout();
        throw error;
      }
    }
  }
}
