import { NationInterface } from "game/services/NationService/NationServiceInterface";
import Panel from "shared/components/atoms/panel/panel";
import { CommanderModelInterface } from "game/services/CommanderService/CommanderServiceInterface";
import { EnrollRequest } from "game/services/ArmyService/ArmyServiceInterface";
import { useState } from "react";
import { ComponentTitle, TxtError, TxtMuted } from "shared/components/atoms/txt/txt";
import Form, { Field, FormAction } from "shared/components/atoms/form/form";
import NationSelector from "game/components/molecules/nations/nationSelector";
import CommanderModelSelector from "game/components/molecules/commander/commanderModelSelector";
import Btn from "shared/components/atoms/btn/btn";

interface EnrollFormProps {
    nations?: NationInterface[];
    commanderModels?: CommanderModelInterface[];
    onSubmit?: (data: EnrollRequest) => Promise<any>;
}

export default function EnrollForm(
  { nations, commanderModels, onSubmit }: EnrollFormProps
) {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<any>();
  const [formData, setFormData] = useState({
    armyName: "",
    nationId: "",
    lordName: "",
    commanderModelId: "",
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!!onSubmit) {
        setSubmitting(true);
        onSubmit(formData)
            .catch(setError)
            .finally(() => setSubmitting(false));
    }
  };


  return (
    <Panel>
        <ComponentTitle>Recruter une armée</ComponentTitle>
      {error && <TxtError>{error.message}</TxtError>}
      <Form onSubmit={handleSubmit}>
      <Field
        label="Nom armée"
        className="field"
        type="text"
        placeholder="Nom"
        name="armyName"
        value={formData.armyName}
        onChange={(e: any) => setFormData({ ...formData, armyName: e.target.value })}
        required
      />
      <div className="input-group">
          <TxtMuted>Nation</TxtMuted>
          <NationSelector
            nations={nations}
            selected={formData.nationId}
            onSelect={(nationId: string) => setFormData({ ...formData, nationId: nationId, commanderModelId: "" })}
            />
        </div>
        <Field
        label="Nom du seigneur de l'armée"
        className="field"
        type="text"
        placeholder="Nom du seigneur de l'armée"
        name="lordName"
        value={formData.lordName}
        onChange={(e: any) => setFormData({ ...formData, lordName: e.target.value })}
        required
      />
      <div className="input-group">
          <CommanderModelSelector
            commanderModels={commanderModels?.filter((model) => model.nationId == formData.nationId)}
            selected={formData.commanderModelId}
            onSelect={(modelId: string) => setFormData({ ...formData, commanderModelId: modelId })}
            />
        </div>
        <FormAction submitting={submitting}>
            <Btn type="submit">
                Recruter cette armée
            </Btn>
        </FormAction>
      </Form>
    </Panel>
  );
}
