import Icon, { CommanderModelIcon, UnitModelIcon } from "game/components/atoms/icons";
import { BattleInterface, BattleServiceInterface } from "game/services/MoveService/MoveServiceInterface";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import Card, { CardContainer, CardHead } from "shared/components/atoms/card";
import PageContainer from "shared/components/atoms/container/container";
import Panel from "shared/components/atoms/panel/panel";
import Pos from "shared/components/atoms/position";
import Txt, { Important, PageTitle } from "shared/components/atoms/txt/txt";
import PageLoading from "shared/pages/loading";
import NotFound from "shared/pages/notFound";

interface BattleProps {
  battleService: BattleServiceInterface;
}
export default function Battle({ battleService }: BattleProps) {
  const { id } = useParams();
  const [battle, setBattle] = useState<BattleInterface>();

  useEffect(() => {
    id && battleService.get(id)
      .then(setBattle);
  }, [id, battleService]);

  if (battle === undefined) return <PageLoading />;
  if (!battle) return <NotFound />;

  return (
    <PageContainer>
      <Panel>
        <PageTitle>Rapport de Bataille</PageTitle>
        {
          battle.logs.map((log) => {
            switch (log.type) {
              case "SquadLog":
                return <div key={log.id}>
                  <Txt>Forces présentes en {log.data.side == "attacker" ? "attaque" : "défense"}</Txt>
                  <CardContainer>
                    {log.data.soldiers.map((soldier: any) => {
                      return <Card size="sm" key={soldier.id}>
                        <CardHead>
                          {
                            soldier.type === 'commander' ? 
                            <CommanderModelIcon model={soldier.model} /> :
                            <UnitModelIcon model={soldier.model} />
                          }
                        </CardHead>
                      </Card>
                    })}
                  </CardContainer>
                  </div>
              case 'AttackLog':
                return <Txt key={log.id}><Important>{log.data.actor}</Important> attaque <Important>{log.data.target}</Important> ({log.data.damages} dégâts)</Txt>
              case 'DeathLog':
                return <Txt key={log.id}><Important>{log.data.target}</Important> meurt ({log.data.looted ?? 0} Or pillé)</Txt>
              case 'MoveLog':
                return <Txt key={log.id}><Important>{log.data.actor}</Important> {log.data.type == 'NORMAL' ? "se déplace" : "fuit"} en <Important><Pos pos={log.data.pos}/></Important></Txt>
                case 'BattleLog':
                  return <Txt key={log.id}>{log.data.txt}</Txt>
              default:
                return <Txt key={log.id}>{log.type}</Txt>
            }
          })
        }
        <Txt><Link className="link" to={"/game/view/MAIN/"+ battle.where.x + "/" + battle.where.y}>Retour à la vue</Link></Txt>
      </Panel>
    </PageContainer>
  );
}
