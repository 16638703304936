import * as React from "react";

import Panel from "shared/components/atoms/panel/panel";
import { useNavigate } from "react-router-dom";
import AuthServiceInterface, { ForgetPasswordRequest, LoginRequest } from "shared/services/AuthService/AuthServiceInterface";
import { PageTitle, TxtError } from "shared/components/atoms/txt/txt";
import LoginForm from "game/components/organisms/form/loginForm";
import PageContainer from "shared/components/atoms/container/container";
import ForgetPasswordForm from "game/components/organisms/form/forgetPasswordForm";

interface ForgetPasswordFormProps {
  authService: AuthServiceInterface
}

export default function ForgetPassword({ authService }: ForgetPasswordFormProps) {
  const navigate = useNavigate();

  const [error, setError] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [sended, setSended] = React.useState<boolean>(false);

  const handleSubmit = (formData: ForgetPasswordRequest) => {
    setLoading(true);
    authService.forgetPassword(formData).then((response) => {
      setLoading(false);
      setSended(true);
    },
      (error) => {
        setError(error);
        setLoading(false);
        setSended(false);
      });
  };

    if(authService.isAuth()) {
      navigate('/game');
      return <></>;
    }

    if(sended) return <PageContainer>
        <Panel>
            <PageTitle>Requête envoyée</PageTitle>
        </Panel>
    </PageContainer>
  return (
    <PageContainer>
      <Panel>
        <PageTitle>Mot de passe oublié</PageTitle>
        {error && <TxtError>Une erreur est survenue</TxtError>}
        <ForgetPasswordForm onSubmit={handleSubmit} submitting={loading}></ForgetPasswordForm>
      </Panel>
    </PageContainer>
  );
}
