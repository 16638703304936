export interface TerrainInterface {
    id: string;
    name: string;
    type: number;
    sprite: string;
    spriteType: string;
}

export default interface TerrainServiceInterface {

}

export default function terrainCost(terrain: TerrainInterface){
    switch(terrain.type) {
        case 0: 
          return 5;
        case 1:
          return 7;
        case 2:
          return 6;
        case 3:
          return 7;
      }
      return 10;
}