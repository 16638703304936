import React from "react";
import ReactDOM from "react-dom";
import ArmyService, { ArmyApi } from "game/services/ArmyService/ArmyService";
import BuildingModelService from "game/services/BuildingService/BuildingService";
import CityService from "game/services/CityService/CityService";
import CommanderModelService from "game/services/CommanderService/CommanderModelService";
import CommanderService, { CommanderApi, UnitApi, UnitService } from "game/services/CommanderService/CommanderService";
import MapService from "game/services/MapService/MapService";
import MoveService, { BattleService, MoveApi } from "game/services/MoveService/MoveService";
import NationService from "game/services/NationService/NationService";
import reportWebVitals from "./reportWebVitals";

import AuthService from "./shared/services/AuthService/AuthService";
import Zon2k20 from "./Zon2k20";
import EventHub from "shared/services/Observer/EventHub";

const authService = new AuthService();
const eventHub = new EventHub();

const armyApi = new ArmyApi(authService,eventHub);
const commanderApi = new CommanderApi(authService);
const moveApi = new MoveApi(authService);
const unitApi= new UnitApi(authService);

const armyService = new ArmyService(armyApi, eventHub);
const battleService = new BattleService(authService);
const buildingModelService = new BuildingModelService(authService);
const cityService = new CityService(authService);
const commanderService = new CommanderService(new CommanderApi(authService), armyApi, eventHub);
const commanderModelsService = new CommanderModelService(authService);
const mapService = new MapService(authService);
const moveService = new MoveService(moveApi, commanderApi, eventHub);
const nationService = new NationService(authService);
const unitService = new UnitService(unitApi, commanderApi, armyApi, eventHub);


ReactDOM.render(
  <React.StrictMode>
    <Zon2k20
      authService={authService}
      eventHub={eventHub}
      armyService={armyService}
      battleService={battleService}
      buildingModelService={buildingModelService}
      cityService={cityService}
      commanderService={commanderService}
      commanderModelsService={commanderModelsService}
      mapService={mapService}
      moveService={moveService}
      nationService={nationService}
      unitService={unitService}
    />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
