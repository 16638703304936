import { CityContainer, CityGrid } from "shared/components/atoms/container/container";
import Crt from "shared/components/atoms/crt/crt";
import { InfoSelector } from "shared/components/atoms/form/form";
import Loader from "shared/components/atoms/loader/loader";
import { MetalPanel } from "shared/components/atoms/panel/panel";
import  { TxtMuted } from "shared/components/atoms/txt/txt";
import { BuildingInterface } from "game/services/BuildingService/BuildingServiceInterface";
import { BuildingIcon } from "game/components/atoms/icons";
import { BuildingModelInfo } from "./buildingModelSelector";

interface BuildingSelectorProps {
    buildings?: BuildingInterface[];
    selected?: string;
    onSelect?: any;
}

export default function BuildingSelector({buildings, selected, onSelect}: BuildingSelectorProps){
    const selectedBuilding = buildings?.find((building) => building.model.id === selected);
    if(!buildings) return <Loader/>;
    if(buildings.length == 0) return <TxtMuted>Aucun bâtiment</TxtMuted>;

    return <InfoSelector>
        <CityContainer>
        <CityGrid>
        {buildings.map((building) => {
              return (
                <div key={building.model.id} onClick={() => onSelect(building.model.id)}> 
                    <BuildingIcon building={building} selected={building.model.id === selected} />
                </div>
              );
            })}
            </CityGrid>
            </CityContainer>
        <MetalPanel>
            <Crt>
                {selectedBuilding && <BuildingModelInfo model={selectedBuilding.model}/>}
                
            </Crt>
        </MetalPanel>
    </InfoSelector>


}