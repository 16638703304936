import AuthService from 'shared/services/AuthService/AuthService';

import http from 'utils/http-common';

const URL = '/game/commander-models/';

export default class CommanderModelService  {
    authService: AuthService;
    
      constructor(authService: AuthService) {
        this.authService = authService;
      }
    
        async list(){
          try {
             const response = await http.get(URL, { headers: this.authService.authHeader() });
             return response.data.models;
          } catch(error: any) {
            if (error.response && error.response.status === 401) {
              this.authService.logout();
              throw error;
            }
          }
        }

        async get(id: string) {
          try {
             const response = await http.get(URL +id, { headers: this.authService.authHeader() });
             return response.data.model;
          } catch(error: any) {
            if (error.response && error.response.status === 401) {
              this.authService.logout();
              throw error;
            }
          }
        }
    }