import Crt from "shared/components/atoms/crt/crt";
import Loader from "shared/components/atoms/loader/loader";
import { MetalPanel } from "shared/components/atoms/panel/panel";
import Table from "shared/components/atoms/table/table";
import Txt, { Muted, TxtImportant, TxtMuted } from "shared/components/atoms/txt/txt";
import { CommanderModelInterface } from "game/services/CommanderService/CommanderServiceInterface";
import { CommanderModelIcon } from "game/components/atoms/icons";
import StatsGrid from "game/components/atoms/stats";

interface CommanderModelSelectorProps {
    commanderModels?: CommanderModelInterface[];
    selected?: string;
    onSelect?: any;
}

export default function CommanderModelSelector({commanderModels, selected, onSelect}: CommanderModelSelectorProps){
    const selectedCommanderModel = commanderModels?.find((commanderModel) => commanderModel.id === selected);
    if(!commanderModels) return <Loader/>;
    return <div className="info-selector">
        <Table>
            <tbody>
        {commanderModels.map((commanderModel) => {
              return (
                <tr 
                    key={commanderModel.id} 
                    className={
                        "table-row" +
                        (commanderModel.id === selected ? " selected" : "")
                      }
                    onClick={() => onSelect(commanderModel.id)}> 
                <td>
                    <CommanderModelIcon
                        model={commanderModel}
                     />
                </td>
                <td>
                    <TxtImportant>{commanderModel.name}</TxtImportant>
                </td>
                </tr>
              );
            })}
            </tbody>
        </Table>
        <MetalPanel>
            <Crt>
                {selectedCommanderModel && <>
                    <TxtImportant>{selectedCommanderModel.name}</TxtImportant>
                    <TxtMuted>{selectedCommanderModel.description}</TxtMuted>
                    <TxtMuted>Charactéristiques</TxtMuted>
                    <StatsGrid stats={selectedCommanderModel.baseStats}/>
                </>}
            </Crt>
        </MetalPanel>
    </div>


}