import AbstractSubject from "../Observer/AbstractSubject";
import AuthServiceInterface, { RegisterRequest, LoginRequest, ForgetPasswordRequest, ChangePasswordRequest } from "./AuthServiceInterface";
import http from '../../../utils/http-common';
import { AxiosError } from "axios";

export default class AuthService extends AbstractSubject implements AuthServiceInterface{
    register = async (dto: RegisterRequest) => {
        const response = await http.post('/register', dto);

        localStorage.removeItem('token');
        if (response.data.token) {
            localStorage.setItem("token", response.data.token);
            this.emit('token', response.data.token);
        }

        return;
    }

    login = async (dto: LoginRequest) => {
        const response = await http.post('/login_check', dto);

        localStorage.removeItem('token');
        if (response.data.token) {
            localStorage.setItem("token", response.data.token);
            this.emit('token', response.data.token);
        }
        return;
    }

    async forgetPassword(dto: ForgetPasswordRequest): Promise<void> {
        await http.post('/forget-password/'+ encodeURIComponent(dto.email));
        localStorage.removeItem('token');
    }

    async changePassword(dto: ChangePasswordRequest): Promise<void> {
        const response = await http.patch('/users', dto, { headers: dto.token ? {Authorization: 'Bearer ' + dto.token} : this.authHeader() });
        localStorage.removeItem('token');
        if (response.data.token) {
            localStorage.setItem("token", response.data.token);
            this.emit('token', response.data.token);
        }
        return;
    }

    async logout(){
        localStorage.removeItem('token');  
        this.emit('token', null);
        return; 
    }

    handle401(error: AxiosError): never{
        if (error.response && error.response.status === 401) {
            this.logout();
          }
          throw error;
    }

    getToken(): string|null {
        return localStorage.getItem("token") ?? null;
    }

    parseJwt (token: string) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    };

    getParsedToken(): any {
        const token = this.getToken();
        if(token === null)
            return null;
        return this.parseJwt(token);
    }

    isAuth() {
        return this.getToken() !== null;
    }

    authHeader() {
        const token = localStorage.getItem("token");
        if (token !== null) {
          return { Authorization: 'Bearer ' + token };
        }
          return { Authorization: false};
      }
}