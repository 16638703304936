import PageContainer from "shared/components/atoms/container/container";
import Loader from "shared/components/atoms/loader/loader";
import Panel from "shared/components/atoms/panel/panel";
import { PageTitle } from "shared/components/atoms/txt/txt";

export default function PageLoading(){
    return <PageContainer>
        <Panel>
            <PageTitle>Chargement...</PageTitle>
            <Loader></Loader>
        </Panel>
    </PageContainer>
}