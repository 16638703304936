import AuthService from "shared/services/AuthService/AuthService";
import AbstractSubject from "shared/services/Observer/AbstractSubject"
import httpCommon from "utils/http-common";
import MapServiceInterface, { ShowMapRequest, ViewInterface } from "./MapServiceInterface";

export default class MapService implements MapServiceInterface {
    authService: AuthService;

    constructor(authService: AuthService) {
        this.authService = authService;
      }

    async show(id: string, data: ShowMapRequest) {
        try {
          const response = await httpCommon.get('/game/maps/'+ id ,  {
            headers: this.authService.authHeader(),
            params: data
          });

          return response.data.tiles;
        } catch (error: any) {
          if (error.response && error.response.status === 401) {
            this.authService.logout();
            throw error;
          }
        }
      }
} 