
import Loader from "shared/components/atoms/loader/loader";
import Table from "shared/components/atoms/table/table";
import Txt, { Muted, TxtImportant } from "shared/components/atoms/txt/txt";
import { CommanderInterface } from "game/services/CommanderService/CommanderServiceInterface";
import { CommanderIcon } from "game/components/atoms/icons";

interface CommanderSelectorProps {
    commanders?: CommanderInterface[];
    selected?: string;
    onSelect?: any;
}

export default function CommanderSelector({ commanders, selected, onSelect }: CommanderSelectorProps) {
    if (!commanders) return <Loader />;
    return <Table>
            <tbody>
            {commanders.map((commander) => {
                return (
                    <tr
                        key={commander.id}
                        className={
                            "table-row" +
                            (commander.id === selected ? " selected" : "")
                        }
                        onClick={() => onSelect(commander.id)}>
                        <td>
                            <CommanderIcon commander={commander}/>
                        </td>
                        <td>
                            <TxtImportant>{commander.name}</TxtImportant>
                        </td>
                        <td>
                            <Txt>{commander.ap} <Muted>PA</Muted></Txt>
                        </td>
                        <td>
                        <Txt>
                        {commander.units?.reduce((previousValue, currentValue) => previousValue + currentValue.model.baseStats.charisma, 0) ?? '?'} / {commander.model.baseStats.charisma} <Muted>CHA</Muted>
                    </Txt>
                        </td>
                    </tr>
                );
            })}
            </tbody>
        </Table>
}