import * as React from "react";

import Panel from "shared/components/atoms/panel/panel";
import { Navigate, useNavigate } from "react-router-dom";
import AuthServiceInterface, { RegisterRequest } from "shared/services/AuthService/AuthServiceInterface";
import { PageTitle, TxtError } from "shared/components/atoms/txt/txt";
import RegisterForm from "game/components/organisms/form/registerForm";
import PageContainer from "shared/components/atoms/container/container";

interface RegisterFormProps {
  authService: AuthServiceInterface
}

export default function Register({ authService }: RegisterFormProps) {

  return (
    <PageContainer>
        <RegisterForm authService={authService}></RegisterForm>
    </PageContainer>
  );
}
