import React from "react";
import { Position } from "utils/Position";
import Sprite from "./Sprite";

interface ElementProps {
  sprite: string;
  pos: Position;
  origin: Position;
  w: number;
  h: number;
}

export default class Element extends React.Component<ElementProps> {
  render() {
    return (
      <Sprite
        className="element"
        image={`/rsc/${this.props.sprite}.png`.toLowerCase()}
        position={{
          x: (this.props.pos.x - this.props.origin.x) * 32,
          y: (this.props.pos.y - this.props.origin.y) * 32,
          z: 1,
        }}
        data={{ x: 0, y: 0, w: this.props.w, h: this.props.h }}
      />
    );
  }
}

export function CommanderElement({sprite, pos,origin,  w, h}:ElementProps){
  return (
    <Sprite
    className="element"
    image={`/rsc/commanders/${sprite}.png`.toLowerCase()}
    position={{
      x: (pos.x - origin.x) * 32,
      y: (pos.y - origin.y) * 32,
      z: 1,
    }}
    data={{ x: 0, y: 0, w: w, h: h }}
    />
  );
}

export function CityElement({sprite, pos,origin,  w, h}:ElementProps){
  return (
    <Sprite
    className="element"
    image={`/rsc/cities/${sprite}.png`.toLowerCase()}
    position={{
      x: (pos.x - origin.x) * 32,
      y: (pos.y - origin.y) * 32,
      z: 1,
    }}
    data={{ x: 0, y: 0, w: w, h: h }}
    />
  );
}