import PageContainer from "shared/components/atoms/container/container";
import Crt from "shared/components/atoms/crt/crt";
import Panel, { MetalPanel } from "shared/components/atoms/panel/panel";
import Txt, { ComponentTitle, Muted, TxtMuted } from "shared/components/atoms/txt/txt";
import CommanderServiceInterface, { CommanderInterface, DismissSoldierRequest } from "game/services/CommanderService/CommanderServiceInterface";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageLoading from "shared/pages/loading";
import NotFound from "shared/pages/notFound";
import EventHub from "shared/services/Observer/EventHub";
import CommanderSquadSelector from "game/components/molecules/commander/commanderSquadSelector";
import { FormAction } from "shared/components/atoms/form/form";
import Btn from "shared/components/atoms/btn/btn";

interface CommanderProps {
    eventHub: EventHub;
    commanderService: CommanderServiceInterface;
}


export default function Commander({ eventHub, commanderService }: CommanderProps) {
    const { id } = useParams();
    const [commander, setCommander] = useState<CommanderInterface | null>();

    const reload = () => {
        if (!id) {
            setCommander(null);
            return;
        }
        commanderService.show(id).then(setCommander);
    };

    useEffect(() => reload(), [id, commanderService]);

    if (commander === undefined) return <PageLoading></PageLoading>;
    if (commander === null) return <NotFound></NotFound>;

    return <PageContainer>
        <CommanderSquadPanel
            commander={commander}
            dismiss={(dto) => commanderService.dismiss(dto)}
            reload={reload}
        />
    </PageContainer>;
}

interface CommanderInfoPanelProps {
    commander: CommanderInterface;
}

interface CommanderPanelProps {
    commander: CommanderInterface;
    dismiss: (dto: DismissSoldierRequest) => Promise<void>;
    reload: any;
}

export function CommanderSquadPanel({ commander, dismiss, reload }: CommanderPanelProps) {
    const [selected, setSelected] = useState<string>(commander.id);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const dismissUnit = () => {
        if (selected && window.confirm("Voulez-vous vraiment renvoyer cette unité ?")) {
            console.log("Dismiss unit");
            setLoading(true);
            dismiss({ commanderId: commander.id, soldierId: selected })
                .then(() => reload)
                .catch((error) => setError(error.message))
                .finally(() => setLoading(false));

        }
    };

    const dismissCommander = () => {
        if (selected && window.confirm("Voulez-vous vraiment dissoudre cette troupe ?")) {
            setLoading(true);
            dismiss({ commanderId: commander.id, soldierId: selected })
                .then(() => reload)
                .catch((error) => setError(error.message))
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        setSelected(commander.id);
    }, [commander])

    return <Panel>
        <ComponentTitle neon={true}>{commander.name}</ComponentTitle>
        {error ? <Txt>{error}</Txt> : null}
        <CommanderSquadSelector commander={commander} selected={selected} onSelect={setSelected} />
        <FormAction submitting={loading}>
            {commander.id === selected ?
                <Btn onClick={dismissCommander}>
                    Dissoudre cette troupe
                </Btn> :
                <Btn onClick={dismissUnit}>
                    Renvoyer
                </Btn>
            }
        </FormAction>

    </Panel>
}


export function CommanderSquadInfoPanel({ commander }: CommanderInfoPanelProps) {
    const [selected, setSelected] = useState<string>(commander.id);

    return <Panel>
        <ComponentTitle neon={true}>{commander.name}</ComponentTitle>
        <CommanderSquadSelector commander={commander} selected={selected} onSelect={setSelected} />
    </Panel>
}