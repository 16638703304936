import "./menu.css";

import Crt from "shared/components/atoms/crt/crt";
import { MetalPanel } from "shared/components/atoms/panel/panel";
import Txt, { Important, Muted, TxtImportant, TxtMuted } from "shared/components/atoms/txt/txt";
import { Link } from "react-router-dom";
import { ArmyInterface, calcIncome, calcUpkeep } from "game/services/ArmyService/ArmyServiceInterface";
import { Row } from "shared/components/atoms/container/container";
import Btn from "shared/components/atoms/btn/btn";
import Bar from "game/components/atoms/bar/bar";
import { CommanderInterface } from "game/services/CommanderService/CommanderServiceInterface";
import { CityIcon, CommanderIcon, NationIcon } from "game/components/atoms/icons";
import { useEffect, useState } from "react";
import { FormAction } from "shared/components/atoms/form/form";
import { CityInterface } from "game/services/CityService/CityServiceInterface";
import Pos from "shared/components/atoms/position";

interface GameMenuProps {
  army: ArmyInterface;
  commanders: CommanderInterface[];
  cities: CityInterface[];
  selected?: string;
  onActivate: (id: string) => Promise<any>;
}

export default function GameMenu({ army, commanders, cities, selected, onActivate }: GameMenuProps) {
  const [activate, setActivate] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(new Date());

  useEffect(() => {
    const secTimer = setInterval(() => {
      setDate(new Date())
    }, 1000)

    return () => clearInterval(secTimer);
  }, []);

  const nextTurn = new Date(army.nextTurn ?? 'now');

  return (
    <nav className="menu">
      <MetalPanel>
        <Row>
          <div className="icon-container"><NationIcon nation={army.nation} /> </div>
          <div>
            <TxtImportant>{army.name}</TxtImportant>
            <Txt>Armée des <Important>{army.nation.name}</Important></Txt>
          </div>
        </Row>
        <Txt>{army.treasury} <Muted>Or</Muted> {<Important>{calcIncome(army, commanders, cities) - calcUpkeep(army, commanders, cities)} Or/Tour</Important>}</Txt>
        <Txt>{date <= nextTurn ? <Important>Tour disponible depuis le </Important> : <Muted>Prochain tour le </Muted>} {army.nextTurn}</Txt>
        <FormAction submitting={activate}>
          <Btn onClick={
            () => {
              setActivate(true)
              onActivate(army.id).finally(() => setActivate(false));
            }
          }>Activer</Btn>

        </FormAction>

      </MetalPanel>
      <div>
        {commanders.map((commander: CommanderInterface) => {
          return (
            <MetalPanel key={commander.id} selected={commander.id === selected} muted={commander.id !== selected}>
              <Row>
                <div className="icon-container">
                  <CommanderIcon commander={commander} />
                  {commander.wounds !== undefined && <Bar type="health" size="sm" progression={(commander.model.baseStats.health - commander.wounds) / commander.model.baseStats.health * 100} />}
                </div>
                <Crt>
                  <TxtImportant><Link to={'/game/commanders/' + commander.id}>{commander.name}</Link></TxtImportant>
                  {commander.pos ? <TxtMuted><Link className="stretched-link" to={'/game/view/' + commander.mapId + '/' + commander.pos.x + '/' + commander.pos.y}><Pos pos={commander.pos} /> - [Voir]</Link></TxtMuted> : <TxtMuted>Position inconnue</TxtMuted>}
                  <Txt>{commander.ap} <Muted>PA</Muted></Txt>
                </Crt>
              </Row>
            </MetalPanel>
          );
        })}
        {((commanders.length ?? 0) < 5) ? <MetalPanel>
          <TxtImportant>
            <Link to={"/game/commanders/recruit"}>Recruter</Link>
          </TxtImportant>
        </MetalPanel> : null}
      </div>
      <div>
        {cities.map((city: CityInterface) => {
          return (
            <MetalPanel key={city.id}>
              <Row>
                <div className="icon-container">
                  <CityIcon city={city} />
                </div>
                <Crt>
                  <TxtImportant><Link to={'/game/cities/' + city.id}>{city.name}</Link></TxtImportant>
                  {city.pos && <TxtMuted>[{city.pos.x};{city.pos.y}] <Link to={'/game/view/' + city.mapId + '/' + city.pos.x + '/' + city.pos.y}>Voir</Link></TxtMuted>}
                  <Txt><Link to={'/game/cities/' + city.id}>Administrer</Link></Txt>
                </Crt>
              </Row>
            </MetalPanel>
          );
        })}
      </div>

    </nav>
  );
}