import './collapse.css';

interface NavCollapseProps{
    collapse: boolean;
    children?: any;
}

export default function NavCollapse({collapse, children}: NavCollapseProps){
    return <nav className={"nav collapse" + (collapse ? "" : " show")}>
        {children}
    </nav>;
}