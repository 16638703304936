import "./header.css";

import { Component } from "react";
import { Link } from "react-router-dom";
import { AppTitle } from "../txt/txt";

export default class Header extends Component {
    render() {
        return <header className="header">
        <AppTitle><Link to="/">ZON2K20</Link></AppTitle>
    </header>;
    }
}