import EntityCache from "shared/services/Cache/EntityCache";
import EventHub from "shared/services/Observer/EventHub";
import httpCommon from "utils/http-common";
import AuthService from "../../../shared/services/AuthService/AuthService";
import { ArmyApi } from "../ArmyService/ArmyService";
import { ArmyInterface, ReportInterface } from "../ArmyService/ArmyServiceInterface";
import { MakeMoveResponse } from "../MoveService/MoveServiceInterface";
import CommanderServiceInterface, {
  CommanderInterface,
  DismissSoldierRequest,
  ListActionsRequest,
  RecruitCommanderRequest,
  RecruitCommanderResponse,
  RecruitUnitRequest,
  RespawnCommanderResponse,
  RespawnRequest,
  ShowCommanderResponse,
  UnitServiceInterface,
  UseActionRequest,
} from "./CommanderServiceInterface";

const URL = "/game/commanders/";

export class CommanderApi {
  authService: AuthService;

  constructor(authService: AuthService) {
    this.authService = authService;
  }

  async dismiss(dto: DismissSoldierRequest): Promise<void> {
    const response = await httpCommon.delete(
      "/game/commanders/" + dto.commanderId + "/soldiers/" + dto.soldierId,
      {
        headers: this.authService.authHeader(),
      }
    );
    return response.data;
  }

  async show(id: string): Promise<CommanderInterface | null> {
    const response = await httpCommon.get<ShowCommanderResponse>(
      "/game/commanders/" + id,
      {
        headers: this.authService.authHeader(),
      }
    );

    return response.data.commander;
  }

  async list(): Promise<CommanderInterface[]> {
    const response = await httpCommon.get(
      "/game/commanders",
      {
        headers: this.authService.authHeader(),
      }
    );

    return response.data.commanders;
  }

  async searchByArmyId(id: string): Promise<CommanderInterface[]> {
    const response = await httpCommon.get(
      "/game/armies/" + id + "/commanders",
      {
        headers: this.authService.authHeader(),
      }
    );
    return response.data.commanders;
  }

  async recruit(
    data: RecruitCommanderRequest
  ): Promise<RecruitCommanderResponse> {
    const response = await httpCommon.post("/game/commanders", data, {
      headers: this.authService.authHeader(),
    });
    return response.data;
  }

  async respawn(dto: RespawnRequest): Promise<RespawnCommanderResponse> {
    const response = await httpCommon.post(
      "/game/commanders/" + dto.commanderId + "/respawns",
      null,
      { headers: this.authService.authHeader() }
    );
    return response.data;
  }

  async listActions(dto: ListActionsRequest): Promise<any> {
    const response = await httpCommon.get(
      "/game/commanders/" + dto.commanderId + "/actions",
      { headers: this.authService.authHeader(), params: {...dto.pos, ...dto.options} }
    );
    return response.data;
  }

  async useAction(dto: UseActionRequest): Promise<any> {
    const response = await httpCommon.post(
      "/game/commanders/" + dto.commanderId + "/actions",
      {action: dto.actionId,  ...dto.pos, ...dto.options},
      { headers: this.authService.authHeader(),  }
    );
    return response.data;
  }
}

export class UnitApi{
  authService: AuthService;

  constructor(authService: AuthService) {
    this.authService = authService;
  }

  async recruit(data: RecruitUnitRequest): Promise<void> {
    await httpCommon.post("/game/units", data, {
      headers: this.authService.authHeader(),
    });
    return;
  }
}

export class UnitService implements UnitServiceInterface {
  api: UnitApi;
  commanderApi: CommanderApi;
  armyApi: ArmyApi;

  hub: EventHub;

  constructor(api: UnitApi, commanderAPi: CommanderApi, armyApi: ArmyApi, hub: EventHub) {
    this.api = api;
    this.commanderApi = commanderAPi;
    this.armyApi = armyApi;
    this.hub = hub;
  }

  async recruit(data: RecruitUnitRequest): Promise<void> {
    const response = await this.api.recruit(data);

    const commander = await this.commanderApi.show(data.commanderId);
    if(!commander) throw 'UNEXPECTED ERROR';

    this.hub.emit("commanders:updated", commander.id);
    this.hub.emit("armies:updated", await this.armyApi.show(commander.owner.id));

    return response;
  }
}

export default class CommanderService implements CommanderServiceInterface {
  api: CommanderApi;
  armyApi: ArmyApi;
  storage: EntityCache<CommanderInterface>;
  hub: EventHub;

  constructor(api: CommanderApi, armyApi: ArmyApi, hub: EventHub) {
    this.api = api;
    this.armyApi = armyApi;
    this.storage = new EntityCache<CommanderInterface>();
    this.hub = hub;

    this.hub.on('reports:created', (report: ReportInterface) => {
        report.logs.forEach(async (log) => {
          switch(log.type) {
            case 'TYPE_MOVE':
              const actor = await this.show(log.data.commander.id);
              if(!actor) return;
              this.update({...actor, pos: log.data.end});
              console.log(this.storage.get(actor.id));
              this.hub.emit('commanders:updated', this.storage.get(actor.id));
              break;
            default:
              break;
          }
        })
    });
  }

  update(commander: CommanderInterface | null) {
    if (!commander) return;
    this.storage.set(commander.id, commander);
  }

  async dismiss(dto: DismissSoldierRequest): Promise<void> {
    const r = await this.api.dismiss(dto);
    this.update(await this.api.show(dto.commanderId));
    return;
  }

  async show(id: string): Promise<CommanderInterface | null> {
    if (!this.storage.isFresh(id)) {
      this.update(await this.api.show(id));
    }

    return this.storage.get(id);
  }

  async list(): Promise<CommanderInterface[]> {
    const response = await this.api.list();
    response.forEach((commander) => {
      this.update(commander);
    });
    return this.storage.list();
  }

  async searchByArmyId(id: string): Promise<CommanderInterface[]> {
    const response = await this.api.searchByArmyId(id);
    response.forEach((commander) => {
      this.update(commander);
    });
    return this.storage.list().filter((commander) => commander.owner.id === id);
  }

  async recruit(
    data: RecruitCommanderRequest
  ): Promise<RecruitCommanderResponse> {
    const response = await this.api.recruit(data);

    const commander = await this.api.show(response.commanderId);
    if(!commander) throw 'UNEXPECTED ERROR';

    this.hub.emit("commanders:created", commander.id);
    this.hub.emit("armies:updated", await this.armyApi.show(commander.owner.id));

    return response;
  }

  async respawn(dto: RespawnRequest): Promise<RespawnCommanderResponse> {
    const response = await this.api.respawn(dto);

    this.hub.emit("commanders:updated", response);
    await this.api.show(response.commanderId);

    return response;
  }

  async listActions(dto: ListActionsRequest): Promise<any> {
    return await this.api.listActions(dto);
  }

  async useAction(dto: UseActionRequest): Promise<any> {
    const response = await this.api.useAction(dto);

    return response;
  }
}
