import "./bar.css";

interface BarProps {
    type: string;
    size: string;
    progression: number;
    children?: any;
}

export default function Bar({type, size, progression, children}: BarProps){
    return(
        <div className={"bar-container bar-container-"+ size +" bar-container-"+ type}>
            <div className={"bar bar-"+ type}
            style={{
                width: progression +"%"
            }}
            ></div>
            <span className="inner-txt">
                {children}
            </span>
            
        </div>
    );
}