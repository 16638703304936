import "./Zon2k20.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ArmyServiceInterface, { ArmyInterface, ReportInterface } from "./game/services/ArmyService/ArmyServiceInterface";
import AuthServiceInterface, { ParsedToken } from "./shared/services/AuthService/AuthServiceInterface";
import CommanderServiceInterface, { CommanderInterface, CommanderModelInterface, UnitServiceInterface } from "./game/services/CommanderService/CommanderServiceInterface";

import Nations from "game/pages/nations";
import NationsShow from "game/pages/nations/show";
import Game from "game/pages";
import NotFound from "shared/pages/notFound";
import Layout from "game/components/templates/layout/layout";
import AuthVerify from "game/components/AuthVerify";
import Enroll from "game/pages/armies/Enroll";
import { useCallback, useEffect, useState } from "react";
import NationServiceInterface, { NationInterface } from "game/services/NationService/NationServiceInterface";
import CommanderModelService from "game/services/CommanderService/CommanderModelService";
import MapServiceInterface from "game/services/MapService/MapServiceInterface";
import View from "game/pages/view";
import MoveServiceInterface, { BattleServiceInterface } from "game/services/MoveService/MoveServiceInterface";
import Recruit from "game/pages/commanders/recruit";
import GameOverview from "game/pages/overview";
import CityServiceInterface, { CityInterface } from "game/services/CityService/CityServiceInterface";
import { CityPage } from "game/pages/cities";
import BuildingModelServiceInterface from "game/services/BuildingService/BuildingServiceInterface";
import Home from "shared/pages/home";
import Unauthorized from "shared/pages/unauthorized";
import Forbidden from "shared/pages/forbidden";
import Commander from "game/pages/commander";
import Register from "shared/pages/register";
import Login from "shared/pages/login";
import Logout from "shared/pages/logout";
import ForgetPassword from "shared/pages/forgetPassword";
import ChangePassword from "shared/pages/changePassword";
import Battle from "game/pages/battle";
import EventHub from "shared/services/Observer/EventHub";
import EventToasts from "game/components/organisms/eventToasts";
import { TileInterface } from "game/services/TileService/TileService";
import  useGameEntities  from "game/hooks/useGameEntities";


interface Zon2k20Props {
  authService: AuthServiceInterface;
  eventHub: EventHub;
  armyService: ArmyServiceInterface;
  battleService: BattleServiceInterface;
  buildingModelService: BuildingModelServiceInterface;
  cityService: CityServiceInterface;
  commanderService: CommanderServiceInterface;
  commanderModelsService: CommanderModelService;
  mapService: MapServiceInterface;
  moveService: MoveServiceInterface
  nationService: NationServiceInterface;
  unitService: UnitServiceInterface;
}

const RADIUS = 9;

export default function Zon2k20({
  authService,
  eventHub,
  armyService,
  battleService,
  buildingModelService,
  cityService,
  commanderService,
  commanderModelsService,
  mapService,
  nationService,
  moveService,
  unitService
}: Zon2k20Props) {
  const [token, setToken] = useState<string | null>(authService.getToken());
  const [parsedToken, setParsedToken] = useState<ParsedToken | null>(authService.getParsedToken());
  const [commanderModels, setCommanderModels] = useState<CommanderModelInterface[]>([]);

  const [player, setPlayer] = useState<ArmyInterface | null>(null);
  const [playerCommanders, setPlayerCommanders] = useState<CommanderInterface[]>([]);
  const [playerCities, setPlayerCities] = useState<CityInterface[]>([]);
  const [actor, setActor] = useState<CommanderInterface | null>(null);
  const [reports, updateReports] = useGameEntities<ReportInterface>('reports', [], eventHub);

  //GAME DATA
  const [armies, updateArmies] = useGameEntities<ArmyInterface>('armies', [], eventHub);
  const [cities, updateCities] = useGameEntities<CityInterface>('cities', [], eventHub);
  const [commanders, updateCommanders] = useGameEntities<CommanderInterface>('commanders', [], eventHub);
  const [nations, updateNations] = useGameEntities<NationInterface>('nations', [], eventHub);
  const [tiles, updateTiles] = useGameEntities<TileInterface>('tiles', [], eventHub);


  const refreshView = (id: string, x: number, y: number) => {
    commanderService.list().then(updateCommanders);
    cityService.list().then(updateCities);
    mapService.show(id, { x: x, y: y, radius: RADIUS }).then(updateTiles);
  }

  const selectActor = (id: string) => {
    const newActor = commanders.find((commander) => commander.id === id) ?? null;
    setActor(newActor);
  }

  useEffect(() => {
    armies.forEach((army) => (army.id === player?.id || army.id === parsedToken?.id) ? setPlayer(army) : null);
  }, [armies])

  useEffect(() => {
    commanders.forEach((commander) => commander.id === actor?.id ? setActor(commander) : null);
    setPlayerCommanders(commanders.filter((commander) => commander.owner.id == player?.id));
  }, [commanders])

  useEffect(() => {
    setPlayerCities(cities.filter((city) => city.admin?.id == player?.id));
  }, [cities])

  useEffect(() => {
    armyService.list().then(updateArmies);
    cityService.list().then(updateCities);
    commanderService.list().then(updateCommanders);
    nationService.list().then(updateNations);
    setParsedToken(authService.getParsedToken());
  }, [token]);

  useEffect(() => {
    authService.on('token', setToken);
    return () => {
      authService.off('token', setToken);
    }
  });

  useEffect(() => {
    if (!parsedToken?.id) {
      return;
    }
    armyService.reports(parsedToken.id, new Date(Date.now() - 604800)).then(updateReports);

  }, [parsedToken, armyService, commanderService]);

  useEffect(() => {
    eventHub.on('actor:selected', selectActor);
    actor ? selectActor(actor.id) : selectActor(playerCommanders.find(() => true)?.id ?? '');
    return () => {
      eventHub.off('actor:selected', selectActor);
    }
  }, [player])

  useEffect(() => {
    commanderModelsService.list().then(setCommanderModels);
  }, [commanderModelsService]);


  return (
    <Router>
      <Layout authService={authService} >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/forget-password" element={<ForgetPassword authService={authService} />} />
          <Route path="/forget-password/:token" element={<ChangePassword authService={authService} />} />
          <Route path="/login" element={<Login authService={authService} />} />
          <Route path="/logout" element={<Logout authService={authService} />} />
          <Route path="/register" element={<Register authService={authService} />} />
          <Route
            path="/game"
            element={
              player ? <Game player={player} actor={actor} commanders={playerCommanders} cities={playerCities} eventHub={eventHub} armyService={armyService} /> : <Unauthorized />
            }
          >
            <Route
              path="/game"
              element={
                player ? <GameOverview eventHub={eventHub} armyService={armyService} player={player} commanders={playerCommanders} cities={playerCities} /> : <Unauthorized />
              }
            ></Route>
            <Route
              path="/game/battles/:id"
              element={<Battle battleService={battleService} />}
            />
            <Route
              path="/game/commanders/:id"
              element={<Commander eventHub={eventHub} commanderService={commanderService} />}
            />
            <Route
              path="/game/commanders/recruit"
              element={player ? <Recruit player={player} commanders={playerCommanders} commanderService={commanderService} commanderModels={commanderModels} /> : <Unauthorized />}
            />
            <Route
              path="/game/cities/:id"
              element={<CityPage
                commanders={playerCommanders ?? []}
                cityService={cityService}
                unitService={unitService}
              />}
            />
            <Route
              path="/game/view/:id/:x/:y"
              element={
                parsedToken && <View actor={actor} reports={reports} tiles={tiles} commanders={commanders} cities={cities} armyId={parsedToken?.id} eventHub={eventHub} commanderService={commanderService} mapService={mapService} moveService={moveService} refreshView={refreshView} selectActor={selectActor} />
              }
            />
            <Route path="/game/profile" element={<ChangePassword authService={authService} />} />
          </Route>
          <Route
            path="/game/enroll"
            element={
              parsedToken ? <Enroll armyService={armyService} nationService={nationService} commanderModelService={commanderModelsService} /> : <Unauthorized />
            }
          />

          <Route
            path="/game/nations"
            element={<Nations />}
          />
          <Route
            path="/game/nations/:id"
            element={<NationsShow />}
          />
          <Route path="/401" element={<Unauthorized />} />
          <Route path="/403" element={<Forbidden />} />
          <Route path="/404" element={<NotFound />} />
        </Routes>
      </Layout>
      <AuthVerify authService={authService} />
    </Router>
  );
}
