import "./board.css";

import React from "react";
import { CommanderInterface, ElementDTO } from "game/services/CommanderService/CommanderServiceInterface";
import { TileInterface } from "game/services/TileService/TileService";
import { Position } from "utils/Position";
import Element, { CityElement, CommanderElement } from "../../board/Element";
import Tile from "../../board/Tile";
import { ViewInterface } from "game/services/MapService/MapServiceInterface";
import range from "utils/range";
import { TxtMuted } from "shared/components/atoms/txt/txt";

interface BoardProps {
  view: ViewInterface;
  actor: CommanderInterface|null;
  paths: any[];
  tileSelected: TileInterface | null;
  pathSelected: any;
  origin: Position;
  onTileSelect: CallableFunction;
  moveMode: boolean;
}

export default class Board extends React.Component<BoardProps> {
  render() {
    if (this.props.view.tiles.length === 0) return null;

    return (
      <div className="board-scene">
        <div className="board-container">
          {
            range(19, Math.floor(this.props.origin.y))
              .map(
                (y) => {
                  return <div
                    key={y}
                    className="coord"
                    style={
                      {
                        position: "absolute",
                        left: 0,
                        bottom: (y - this.props.origin.y + 1) * 32 + "px"
                      }
                    }
                  >
                    <TxtMuted>{y}</TxtMuted>
                  </div>
                }
              )
          }
          {
            range(19, Math.floor(this.props.origin.x))
              .map(
                (x) => {
                  return <div
                    key={x}
                    className="coord"
                    style={
                      {
                        position: "absolute",
                        left: (x - this.props.origin.x + 1) * 32 + "px",
                        bottom: 0
                      }
                    }
                  >
                    <TxtMuted>{x}</TxtMuted>
                  </div>
                }
              )
          }
          <div className="board">
            {this.props.view.tiles.filter(
              (tile: TileInterface) => (tile.pos.x > this.props.origin.x - 1)
                && (tile.pos.x < this.props.origin.x + 20)
                && (tile.pos.y > this.props.origin.y - 1)
                && (tile.pos.y < this.props.origin.y + 20)
            ).map((tile) => {
              const tilePath = this.props.paths.find(
                (path) => path.last().pos === tile.pos
              );

              const movable =
                this.props.tileSelected !== null &&
                this.props.actor != undefined &&
                this.props.moveMode &&
                tilePath !== undefined;

              const onClick = this.props.onTileSelect;

              const inPath = this.props.pathSelected
                ? this.props.pathSelected.inPath(tile)
                : false;

              return (
                <Tile
                  key={"tile_" + tile.pos.x + "_" + tile.pos.y}
                  tile={tile}
                  onClick={() => onClick(tile, tilePath)}
                  sprite={tile.terrain.sprite}
                  origin={this.props.origin}
                  pos={tile.pos}
                  movable={movable}
                  inPath={inPath}
                  fog={!tile.isSeen}
                  selected={this.props.tileSelected ? (this.props.tileSelected.pos.x === tile.pos.x && this.props.tileSelected.pos.y === tile.pos.y) : false}
                  w={32}
                  h={32}
                />
              );
            })}
            {this.props.view.commanders.map((element) => {
                return (element.pos &&
                  <CommanderElement
                    key={element.id}
                    sprite={element.model.id}
                    origin={this.props.origin}
                    pos={element.pos}
                    w={32}
                    h={48}
                  />
                );
        
            })}
            {
              this.props.view.cities.map((city) => {
                return (city.pos &&
                  <CityElement
                    key={city.id}
                    sprite={city.owner?.id ?? 'NEUTRAL'}
                    origin={this.props.origin}
                    pos={city.pos}
                    w={32}
                    h={48}
                  />
                );

              })
            }
          </div>
        </div>
      </div>
    );
  }
}
