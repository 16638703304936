import Btn from "shared/components/atoms/btn/btn";
import Form, { Field, FormAction } from "shared/components/atoms/form/form";
import { useState } from "react";


interface LoginFormProps {
  onSubmit?: any;
  submitting: boolean;
}

export default function LoginForm({
  onSubmit,
  submitting = false,
}: LoginFormProps) {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!!onSubmit) onSubmit(formData);
  };

  return (

    <Form onSubmit={handleSubmit}>
      <Field
        label="Email"
        className="field"
        type="text"
        placeholder="Email"
        name="username"
        value={formData.username}
        onChange={(e: any) => setFormData({ ...formData, username: e.target.value })}
        required
      />
      <Field
        label="Mot de passe"
        type="password"
        placeholder="Mot de passe"
        name="password"
        value={formData.password}
        onChange={(e: any) => setFormData({ ...formData, password: e.target.value })}
        required
      />
      <FormAction submitting={submitting}>
            <Btn type="submit">
              Connexion
            </Btn>
      </FormAction>
    </Form>

  );
}