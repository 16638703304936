import './card.css';

interface CardProps {
    children?: any;
    selected?: any;
    size? : string;

    onClick?: any;
}

export default function Card(props: CardProps){
    return <div 
        className={"card card-size-" + (props.size ?? 'md') + (props.selected ? " selected" : "")}
        onClick={props.onClick}
        >
            {props.children}
        </div>
}

export function CardBody(props: any){
    return <main className="card-body">
        {props.children}
    </main>
}

export function CardHead(props: any){
    return <header className="card-head">
        {props.children}
    </header>
}

export function CardFoot(props: any){
    return <footer className="card-foot">
        {props.children}
    </footer>
}


export function CardContainer(props: any){
    return <section className='card-container'>
        {props?.children}
    </section>
}