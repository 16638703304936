import * as React from "react";

import { useNavigate, useParams } from "react-router-dom";
import AuthServiceInterface, { ChangePasswordRequest } from "shared/services/AuthService/AuthServiceInterface";
import ChangePasswordForm from "game/components/organisms/form/changePasswordForm";
import PageContainer from "shared/components/atoms/container/container";

interface ChangePasswordFormProps {
  authService: AuthServiceInterface
  token?: string;
}

export default function ChangePassword({authService }: ChangePasswordFormProps) {
  const navigate = useNavigate();
  const {token}= useParams();

  const [error, setError] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSubmit = (formData: ChangePasswordRequest) => {
    setLoading(true);
    authService.changePassword({...formData, token: token}).then((response) => {
      setLoading(false);
      navigate('/game/profile');
    },
      (error) => {
        setError(error);
        setLoading(false);
      });
  };

  return (
    <PageContainer>
        <ChangePasswordForm onSubmit={handleSubmit} submitting={loading} error={error}></ChangePasswordForm>
    </PageContainer>
  );
}
