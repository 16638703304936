import Btn from "shared/components/atoms/btn/btn";
import Form, { Field, FormAction } from "shared/components/atoms/form/form";
import Panel from "shared/components/atoms/panel/panel";
import { ComponentTitle, TxtError } from "shared/components/atoms/txt/txt";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import AuthServiceInterface from "shared/services/AuthService/AuthServiceInterface";

interface RegisterFormProps {
  authService: AuthServiceInterface
}

export default function RegisterForm({
  authService
}: RegisterFormProps) {

  const navigate = useNavigate();

  const [error, setError] = React.useState<any>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [formData, setFormData] = React.useState({
    username: "",
    password: "",
    repeat_password: "",
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);

    authService.register(formData)
      .then((response) => {
        navigate('/game');
      },
        (error) => {
          setError(error.response.data);
          console.error(error);
        })
      .finally(() => setSubmitting(false));
  };

  return (
    <Panel>
      <ComponentTitle>Inscription</ComponentTitle>
      {error && error?.map((message: any) => <TxtError>{message}</TxtError>)}
      <Form onSubmit={handleSubmit}>
        <Field
          label="Email"
          className="field"
          type="text"
          placeholder="Email"
          name="username"
          value={formData.username}
          onChange={(e: any) => setFormData({ ...formData, username: e.target.value })}
          required
        />
        <Field
          label="Mot de passe"
          type="password"
          placeholder="Mot de passe"
          name="password"
          value={formData.password}
          onChange={(e: any) => setFormData({ ...formData, password: e.target.value })}
          required
        />
        <Field
          label="Répétez le mot de passe"
          type="password"
          placeholder="Mot de passe"
          name="repeat_password"
          value={formData.repeat_password}
          onChange={(e: any) => setFormData({ ...formData, repeat_password: e.target.value })}
          required
        />
        <FormAction submitting={submitting}>
          <Btn type="submit">
            Inscription
          </Btn>
        </FormAction>
      </Form>
    </Panel>
  );
}
