import { NationInterface } from "game/services/NationService/NationServiceInterface";
import { Position } from "utils/Position";
import { CityInterface } from "../CityService/CityServiceInterface";
import { CommanderInterface } from "../CommanderService/CommanderServiceInterface";

export interface ArmyInterface {
  id: string;
  name: string;
  nation: NationInterface;
  treasury?: number;
  lordId: string|null;
  nextTurn?: string;
}

export interface ReportInterface {
  id: string;
  actorId: string;
  when: string;
  where: Position;
  logs: LogInterface[];
}

export interface LogInterface {
  id: number;
  data: any;
  type: string;
}

export interface ActivateArmyTurnResponse {
  armyId: string;
}

export interface ShowArmyResponse {
  army: ArmyInterface;
}

export interface ListArmiesResponse {
  armies: ArmyInterface[];
}


export interface EnrollRequest {
  armyName: string;
  nationId: string;
  lordName: string;
  commanderModelId: string;
}

export interface EnrollResponse {
  armyId: string;
}


export default interface ArmyServiceInterface {
  activate(army: ArmyInterface): Promise<ActivateArmyTurnResponse>;
  list(): Promise<ArmyInterface[]>;
  show(id: string): Promise<ArmyInterface|null>;
  enroll(dto: EnrollRequest): Promise<EnrollResponse>;
  reports(id: string, after: Date): Promise<ReportInterface[]>;
}

export function calcIncome(army: ArmyInterface, commanders: CommanderInterface[], cities: CityInterface[]): number{
  return cities.reduce<number>(
    (carry, city) => carry + (city.income ?? 0),
    1000
  );
}

export function calcUpkeep(army: ArmyInterface, commanders: CommanderInterface[], cities: CityInterface[]): number{
  return commanders.reduce<number>(
    (carry, commander) => carry + (commander.upkeep ?? 0),
    0
  );
}