import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface AuthVerifyProps {
  authService: any;
}

const AuthVerify = ({ authService }: AuthVerifyProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (authService.isAuth()) {
      const decodedJwt = authService.getParsedToken();
      if (decodedJwt.exp * 1000 < Date.now()) {
        authService.logout();
        navigate("/");
      }
    }
  }, [authService, navigate, location]);

  return <div></div>;
};

export default AuthVerify;
