import httpCommon from "../../../utils/http-common";
import AuthService from "../../../shared/services/AuthService/AuthService";
import ArmyServiceInterface, {
  ActivateArmyTurnResponse,
  ArmyInterface,
  EnrollRequest,
  EnrollResponse,
  ListArmiesResponse,
  ReportInterface,
  ShowArmyResponse,
} from "./ArmyServiceInterface";
import EventHub from "shared/services/Observer/EventHub";
import EntityCache from "shared/services/Cache/EntityCache";

const API_URL = "/game/armies";

export class ArmyApi {
  authService: AuthService;
  hub: EventHub;

  constructor(authService: AuthService, hub: EventHub) {
    this.authService = authService;
    this.hub = hub;
  }

  async activate(id: string): Promise<ActivateArmyTurnResponse> {
    const r = await httpCommon.patch(
      API_URL + "/" + id,
      { activate: true },
      { headers: this.authService.authHeader() }
    )
      .catch(this.authService.handle401);
    return r.data;
  }

  async list(): Promise<ArmyInterface[]> {
    const response = await httpCommon.get<ListArmiesResponse>(API_URL, {
      headers: this.authService.authHeader(),
    })
      .catch(this.authService.handle401);
    return response.data.armies;
  }

  async show(id: string): Promise<ArmyInterface | null> {
    const response = await httpCommon.get<ShowArmyResponse>(API_URL + "/" + id, {
      headers: this.authService.authHeader(),
    })
      .catch(this.authService.handle401);
    return response.data.army;
  }

  async enroll(dto: EnrollRequest): Promise<EnrollResponse> {
    const response = await httpCommon
      .post(API_URL, dto, { headers: this.authService.authHeader() })
      .catch(this.authService.handle401);
    return response.data;
  }

  async reports(id: string): Promise<ReportInterface[]> {
    const response = await httpCommon.get(API_URL + "/" + id + "/reports", {
      headers: this.authService.authHeader(),
    })
      .catch(this.authService.handle401);
   return response.data.reports ?? [];
  }
}

export default class ArmyService implements ArmyServiceInterface {
  api: ArmyApi;
  storage: EntityCache<ArmyInterface>;
  hub: EventHub;

  constructor(armyApi: ArmyApi, hub: EventHub) {
    this.api = armyApi;
    this.storage = new EntityCache<ArmyInterface>();
    this.hub = hub;

    // Append the topic(s) to subscribe as query parameter
    const mercureHub = new URL('/.well-known/mercure' ?? '', window.origin);
    mercureHub.searchParams.append('topic', 'https://zon2k20.fr/game/armies/{id}/reports');

    // Subscribe to updates
    const eventSource = new EventSource(mercureHub);
    eventSource.onmessage = event => {
      const msg = JSON.parse(event.data);
      this.hub.emit('reports:'+ msg.type, msg.data);
    }
  }

  async activate(army: ArmyInterface): Promise<ActivateArmyTurnResponse> {
    const response = await this.api.activate(army.id);
    this.hub.emit('armies:updated', await this.show(army.id));
    return response;
  }

  async list(): Promise<ArmyInterface[]> {
    if (!this.storage.isAllFresh()) {
      this.storage.setAll(await this.api.list());
    }

    return this.storage.list();
  }

  async show(id: string): Promise<ArmyInterface | null> {
    if (!this.storage.isFresh(id)) {
      this.storage.set(id, await this.api.show(id));
    }

    return this.storage.get(id);
  }

  async enroll(dto: EnrollRequest): Promise<EnrollResponse> {
    const response = await this.api.enroll(dto);
    this.hub.emit('armies:created', await this.show(response.armyId));
    return response;
  }
  
  async reports(id: string): Promise<ReportInterface[]> {
    return this.api.reports(id);
  }
}
