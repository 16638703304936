import { TileInterface } from "game/services/TileService/TileService";
import React from "react";
import { Position } from "utils/Position";
import Sprite from "./Sprite";

interface TileProps {
  tile: TileInterface;
  movable: boolean;
  selected: boolean;
  inPath: boolean;
  fog: boolean;
  onClick: CallableFunction;
  pos: Position;
  origin: Position;
  w: number;
  h: number;
  sprite: string;
}

export default class Tile extends React.Component<TileProps> {

  handleOnClick = () => {
    this.props.onClick();
  };

  render() {
    return (
      <Sprite
        className={
          "tile" +
          (this.props.movable ? " targetable" : "") +
          (this.props.selected ? " selected" : "") +
          (this.props.inPath ? " in-path" : "") +
          (this.props.fog ? " fog" : "")
        }
        onClick={this.handleOnClick}
        image={this.props.tile.terrain.spriteType == 'autotile' ? `/img/autotile/${this.props.tile.terrain.sprite}/${this.props.tile.bitmask}.png`.toLowerCase() : `/rsc/tile/${this.props.tile.terrain.sprite}.png`.toLowerCase()}
        position={{
          x: (this.props.pos.x - this.props.origin.x) * this.props.w,
          y: (this.props.pos.y - this.props.origin.y) * this.props.h,
        }}
        data={{ x: 0, y: 0, w: this.props.w, h: this.props.h }}
      />
    );
  }
}
