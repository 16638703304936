import Bar from "game/components/atoms/bar/bar";
import Card, {CardFoot, CardHead } from "shared/components/atoms/card";
import { TxtImportant } from "shared/components/atoms/txt/txt";
import { CommanderInterface } from "game/services/CommanderService/CommanderServiceInterface";
import { CommanderIcon } from "game/components/atoms/icons";

interface CommanderHeadPanelProps{
    commander: CommanderInterface;
}

export function CommanderHeadPanel({commander}: CommanderHeadPanelProps){
    return <header className="commander-head-panel">
        
    </header>;
}

interface CommandeCardProps {
    commander: CommanderInterface;

    selected?: any;
    onClick?: any;
}

export default function CommanderCard({commander, selected, onClick}: CommandeCardProps){
    return <Card size="md" selected={selected} onClick={onClick}>
        <CommanderIcon commander={commander}/>
        <CardHead>
            <TxtImportant>
                {commander.name}
            </TxtImportant>
        </CardHead>
        <CardFoot>
            {commander.wounds !== undefined &&<Bar type="health" size="sm" progression={(commander.model.baseStats.health - commander.wounds)/commander.model.baseStats.health*100} />}
        </CardFoot>
    </Card>
}

export function CommanderIconCard({commander, selected, onClick}: CommandeCardProps){
    return <Card size="sm" selected={selected} onClick={onClick}>
        <img 
            className="card-battleback"
            src={("/rsc/battlebacks/"+ commander.model.nationId + ".png").toLowerCase()}
        />
        <CommanderIcon commander={commander}/>
        <CardFoot>
            {commander.wounds !== undefined && <Bar type="health" size="sm" progression={(commander.model.baseStats.health - commander.wounds)/commander.model.baseStats.health*100} />}
        </CardFoot>
    </Card>
}