import "./layout.css";

import Header from "../../../../shared/components/atoms/header/header";
import Topnav from "game/components/organisms/topnav/topnav";
import AuthServiceInterface from "shared/services/AuthService/AuthServiceInterface";

interface LayoutProps {
  authService: AuthServiceInterface
  children?: any;
}

export default function Layout({ authService, children }: LayoutProps) {
  return (
    <div className="layout theme-rust">
      <Header />
      <Topnav authService={authService} />
      <main className="main">{children}</main>
    </div>
  );
}
