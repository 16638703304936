import Tile from "game/components/board/Tile";
import AuthService from "shared/services/AuthService/AuthService";
import { CommanderInterface } from "game/services/CommanderService/CommanderServiceInterface";
import { ViewInterface } from "game/services/MapService/MapServiceInterface";
import AbstractSubject from "shared/services/Observer/AbstractSubject";
import terrainCost from "game/services/TerrainService/TerrainServiceInterface";
import { TileInterface } from "game/services/TileService/TileService";
import httpCommon from "utils/http-common";
import { calcDist, calcDistEuclide, Position } from "utils/Position";
import MoveServiceInterface, { BattleInterface, BattleServiceInterface, MakeMoveResponse, Path } from "./MoveServiceInterface";
import EventHub from "shared/services/Observer/EventHub";
import { CommanderApi } from "../CommanderService/CommanderService";
import { CityInterface } from "../CityService/CityServiceInterface";

export class MoveApi {
    authService: AuthService;

    constructor(authService: AuthService,) {
        this.authService = authService;
      }

    async move(id: string, path: Path): Promise<MakeMoveResponse> {
      try {
        const response = await httpCommon.post('/game/moves', {
          actor_id: id,
          path: JSON.stringify(path.nodes.map((tile) => tile.pos))
        }, {
          headers: this.authService.authHeader(),
        });

        return response.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          this.authService.logout();
          throw error;
        }
        throw error;
      }
    }
  
}

export class BattleApi extends AbstractSubject implements BattleServiceInterface {
  authService: AuthService;

    constructor(authService: AuthService) {
        super();
        this.authService = authService;
      }

  async get(id: string): Promise<BattleInterface>{
    const response = await httpCommon.get('/game/battles/' + id, {
      headers: this.authService.authHeader(),
    })
    return response.data.battle;
  }
}

export default class MoveService implements MoveServiceInterface {
  eventHub: EventHub;
  moveApi: MoveApi;
  commanderApi: CommanderApi;

  constructor(moveApi: MoveApi, commanderApi: CommanderApi, eventHub: EventHub) {
      this.moveApi = moveApi;
      this.commanderApi = commanderApi;
      this.eventHub = eventHub;
    }


  async move(id: string, path: Path): Promise<MakeMoveResponse> {
    const r = await this.moveApi.move(id, path);
    this.commanderApi.show(id).then((commander) => this.eventHub.emit('commanders:updated', commander));
    return r;
  }

  
canMove = (actor: CommanderInterface, tile: TileInterface, commanders: CommanderInterface[], cities: CityInterface[]) => {
  if(!actor.pos) return false;
  const distance = calcDist(actor.pos, tile.pos);
  const canPay = actor.ap >= (terrainCost(tile.terrain) * distance);

  let traversable = true;
  commanders.forEach((commander) => {
      if(commander.pos?.x === tile.pos.x && commander.pos?.y === tile.pos.y && commander.owner.nation.id === actor.owner.nation.id) traversable = false;
  });

  cities.forEach((city) => {
    if(city.pos?.x === tile.pos.x && city.pos?.y === tile.pos.y && city.owner?.id === actor.owner.nation.id) traversable = false;
  });

  return distance < 2 && canPay && traversable;
};

calcPaths = (actor: CommanderInterface, map: ViewInterface): Path[] => {
  var paths: Path[] = [];
  const solutions: {[name: string]: boolean} = {};


  if(!actor.pos) return [];

  this.adjacentTiles(actor.pos, map.tiles).forEach((tile) => {
    if (!this.canMove(actor, tile, map.commanders, map.cities)) return;
    const newPath = Path.createPath(actor, tile);
    paths.push(newPath);
    solutions[tile.pos.x + "_" + tile.pos.y] = true;
  });

  let openPaths = paths;
  while (openPaths.length > 0) {
    openPaths.sort((a, b) => {
      return a.nodes.length - b.nodes.length;
    });
    const parentPath = openPaths.shift();
    if(!parentPath) return [];

    this.adjacentTiles(parentPath.last().pos, map.tiles).forEach((tile) => {
      if (
        !this.canMove(
          {
            ...actor,
            pos: { x: parentPath.last().pos.x, y: parentPath.last().pos.y }
          },
          tile,
          map.commanders,
          map.cities
        )
      )
        return;
      
      const target = map.commanders.find((commander) => {
        return commander.pos?.x === tile.pos.x && commander.pos?.y === tile.pos.y;
      }) ?? map.cities.find((city) => {
        return city.pos?.x === tile.pos.x && city.pos?.y === tile.pos.y;
      }) ;
      const newPath = target ? parentPath.addNode(tile).addTarget(target) : parentPath.addNode(tile);
      
      let push = newPath.canPay();
      if (push && solutions[tile.pos.x + "_" + tile.pos.y] !== undefined) {
        paths.forEach((path, key) => {
          if (newPath.last() !== path.last()) {
            return;
          }

          if (newPath.calcCost() < path.calcCost()) {
            paths.splice(key, 1);
            return;
          }

          if (newPath.calcCost() > path.calcCost()) {
            push = false;
            return;
          }

          if (newPath.nodes.length < path.nodes.length) {
            paths.splice(key, 1);
            return;
          }

          if (newPath.nodes.length > path.nodes.length) {
            push = false;
            return;
          }

          if (newPath.divergence < path.divergence) {
            paths.splice(key, 1);
            return;
          }

          if (newPath.divergence > path.divergence) {
            push = false;
            return;
          }

          push = false;
        });
      }

      if (push) {
        paths.push(newPath);
        solutions[tile.pos.x + "_" + tile.pos.y] = true;
      }
    });

    paths = paths.filter((path) => {
      return !path.haveSameTarget(parentPath);
    });
    paths.push(parentPath.close());
    openPaths = paths.filter((path) => {
      return path.open;
    });
  }

  return paths;
};

adjacentTiles = (pos: Position, tiles: TileInterface[]) => {
  return tiles.filter((tile) => {
    if (tile.pos.x == pos.x && tile.pos.y == pos.y) return false;
    return calcDist(pos, tile.pos) < 2;
  });
};

}
export class BattleService extends AbstractSubject implements BattleServiceInterface {
authService: AuthService;

  constructor(authService: AuthService) {
      super();
      this.authService = authService;
    }

async get(id: string): Promise<BattleInterface>{
  const response = await httpCommon.get('/game/battles/' + id, {
    headers: this.authService.authHeader(),
  })
  return response.data.battle;
}
}