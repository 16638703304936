import { useNavigate } from "react-router-dom";
import AuthServiceInterface, { LoginRequest } from "shared/services/AuthService/AuthServiceInterface";
import { useEffect } from "react";
import PageLoading from "./loading";

interface LogoutFormProps {
  authService: AuthServiceInterface
}

export default function Logout({ authService }: LogoutFormProps) {
  const navigate = useNavigate();

  useEffect(() => {
    authService.logout().finally(() => navigate('/'));
  });

  return <PageLoading></PageLoading>;
}
