import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageContainer from "shared/components/atoms/container/container";
import Panel, { PlasticPanel } from "shared/components/atoms/panel/panel";
import Txt, { PageTitle, TxtImportant } from "shared/components/atoms/txt/txt";
import Intro, { GameboyScreen } from "shared/components/organisms/intro";


export default function Home() {
  const [count, setCount] = useState(0);

  const play = () => {
    setCount((n) => n + 1);
  };

  const replay = () => {
    setCount(0);
  };

  useEffect(() => {
    const id = setInterval(play, 1000 / 24);
    return () => clearInterval(id);
  }, []);


  return (
    <PageContainer>
      <Panel>
        <PageTitle>Bienvenue dans Zon</PageTitle>
        <Txt>Tout ce qui reste de Terra, c'est Zon. Des acres de terres corrompues par les radiations et les zombies. Au delà c'est un désert mort où même les machines n'osent pas s'aventurer</Txt>
        <TxtImportant><Link className="link" to="/register">S'inscrire</Link></TxtImportant>
      </Panel>
      <PlasticPanel>
        <GameboyScreen onClick={() => replay()}>
          <Intro frame={count} />
        </GameboyScreen>
      </PlasticPanel>
    </PageContainer>
  );
}
