import Crt from "shared/components/atoms/crt/crt";
import { InfoSelector } from "shared/components/atoms/form/form";
import Loader from "shared/components/atoms/loader/loader";
import { MetalPanel } from "shared/components/atoms/panel/panel";
import Table from "shared/components/atoms/table/table";
import Txt, { TxtImportant, TxtMuted } from "shared/components/atoms/txt/txt";
import { NationInterface } from "game/services/NationService/NationServiceInterface";
import { NationIcon } from "game/components/atoms/icons";

interface NationSelectorProps {
    nations?: NationInterface[];
    selected?: string;
    onSelect?: any;
}

export default function NationSelector({nations, selected, onSelect}: NationSelectorProps){
    const selectedNation = nations?.find((nation) => nation.id === selected);
    if(!nations) return <Loader/>;
    return <InfoSelector>
        <Table>
          <tbody>
        {nations.map((nation) => {
              return (
                <tr className={
                    "table-row" +
                    (nation.id === selected ? " selected" : "")
                  } key={nation.id} onClick={() => onSelect(nation.id)}> 
                <td>
                    <NationIcon
                      nation={nation}
                      />
                </td>
                <td>
                    <TxtImportant>{nation.name}</TxtImportant>
                </td>
                  <td>
                    <Txt>nb joueurs</Txt>
                  </td>
                </tr>
              );
            })}
            </tbody>
        </Table>
        <MetalPanel>
            <Crt>
                {selectedNation && <>
                    <TxtImportant>{selectedNation.name}</TxtImportant>
                    <TxtMuted>{selectedNation.description}</TxtMuted>
                </>}
            </Crt>
        </MetalPanel>
    </InfoSelector>


}