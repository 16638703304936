import AuthService from "shared/services/AuthService/AuthService";
import AbstractSubject from "shared/services/Observer/AbstractSubject";
import httpCommon from "utils/http-common";
import CityServiceInterface, { BuildRequest, DestroyBuildingRequest, UpgradeCityRequest } from "./CityServiceInterface";

const API_URL = "/game/cities/";

export default class CityService extends AbstractSubject implements CityServiceInterface
{
  authService: AuthService;

  constructor(authService: AuthService) {
    super();
    this.authService = authService;
  }

  async list() {
    try {
      const response = await httpCommon.get(API_URL, {
        headers: this.authService.authHeader(),
      });
      return response.data.cities;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        this.authService.logout();
      }
      throw error;
    }
  }

  async get(id: string) {
    try {
      const response = await httpCommon.get(API_URL + id, {
        headers: this.authService.authHeader(),
      });
      return {
        ...response.data.city, 
        canBuild: response.data.canBuild ?? undefined,
        canRecruit: response.data.canRecruit ?? undefined
      };
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        this.authService.logout();
      }
      throw error;
    }
  }

  async build(data: BuildRequest){
    try {
      const response = await httpCommon.post(API_URL + data.cityId +'/buildings', data, {
        headers: this.authService.authHeader(),
      });
      return;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        this.authService.logout();
      }
      throw error;
    }
  }

  async destroyBuilding(data: DestroyBuildingRequest){
    try {
      const response = await httpCommon.delete(API_URL + data.cityId +'/buildings/'+ data.modelId,  {
        headers: this.authService.authHeader(),
      });
      return;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        this.authService.logout();
      }
      throw error;
    }
  }

  async upgradeCity(data: UpgradeCityRequest): Promise<void> {
    try {
      const response = await httpCommon.patch(API_URL + data.cityId, data, {
        headers: this.authService.authHeader(),
      });
      return;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        this.authService.logout();
      }
      throw error;
    }
  }
}
