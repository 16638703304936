import { ViewInterface } from "game/services/MapService/MapServiceInterface";
import terrainCost from "game/services/TerrainService/TerrainServiceInterface";
import { calcAngle, calcDist, Position } from "utils/Position";
import { CityInterface } from "../CityService/CityServiceInterface";
import { CommanderInterface, ElementDTO } from "../CommanderService/CommanderServiceInterface";
import { TileInterface } from "../TileService/TileService";

export interface MoveInterface {
  actorId: string;
  start: Position;
  end: Position;
}

export interface MakeMoveResponse {
  moves: MoveInterface[];
  battleId?: string;
}

export default interface MoveServiceInterface {
    move(id: string, path: Path): Promise<MakeMoveResponse>;
    calcPaths(actor: CommanderInterface, map: ViewInterface): Path[];
}

export class Path {
  actor: CommanderInterface;
  nodes: TileInterface[];
  open: boolean;
  divergence: number;
  target?: CommanderInterface|CityInterface;

    constructor(
        actor: CommanderInterface, 
        nodes: TileInterface[] = [],
        open = true, 
        divergence = 0,
        target?: CommanderInterface|CityInterface
        ) {
      this.actor = actor;
      this.nodes = nodes;
      this.open = open;
      this.divergence = divergence;
      this.target = target;
      Object.freeze(this);
    }
  
    canPay = () => {
      return this.actor.ap >= this.calcCost();
    };
  
    calcCost = () => {
      var total = 0;
      var previousNode: CommanderInterface|TileInterface = this.actor;
      this.nodes.forEach((node: TileInterface) => {
        if(!previousNode.pos) return;
        const distance = calcDist(previousNode.pos, node.pos);
        total += Math.ceil(terrainCost(node.terrain) * distance);
        previousNode = node;
      });

      return total;
    };

    calcDivergence = (newNode: TileInterface) => {
      //return isDiagonal(this.last(), newNode) ? 1 : 0;
      if(!this.actor.pos) return 0;
      if (this.nodes.length < 2)
        return Math.ceil(
          (calcAngle(this.actor.pos, this.last().pos, newNode.pos) % 180) / 180
        );
      return Math.ceil(
        (calcAngle(this.nodes.slice(-2)[0].pos, this.last().pos, newNode.pos) %
          180) /
          180
      );
    };
  
    addNode = (node: TileInterface) => { 
      const deltaDiv = this.calcDivergence(node) * this.nodes.length;

      return new Path(
        this.actor,
        [...this.nodes, node],
        this.open,
        deltaDiv + this.divergence
      );
    };

    addTarget = (target: CommanderInterface|CityInterface) => {
      if(this.open)
        return new Path(this.actor, this.nodes, false, this.divergence, target);
        return this;
    }
  
    close = () => {
      if(this.open)
        return new Path(this.actor, this.nodes, false);
      return this;
    };
  
    last = () => {
      return this.nodes.slice(-1)[0];
    };
  
    inPath = (tile: TileInterface) => {
      return (
        this.nodes.find((node) => {
          return tile.pos === node.pos;
        }) !== undefined
      );
    };
  
    haveSameTarget = (path: Path) => {
      return this.last().pos === path.last().pos;
    };

    isBattlePath = () => {
      return this.target !== undefined;
    }
  
    static createPath(actor: CommanderInterface, startingNode: TileInterface) {
      return new this(actor, [startingNode], true);
    }
  }

  export interface BattleInterface {
    id: string;
    where: Position;
    logs: LogInterface[];
  }

  export interface LogInterface {
    id: number;
    type: string;
    data: any;
  }

  export interface BattleServiceInterface {
    get(id: string): Promise<BattleInterface>;
  }