import GameMenu from "game/components/organisms/menu/menu";
import { Outlet, useNavigate } from "react-router-dom";
import ArmyServiceInterface, { ArmyInterface, ShowArmyResponse } from "game/services/ArmyService/ArmyServiceInterface";
import { useEffect, useState } from "react";
import NotFound from "shared/pages/notFound";
import PageLoading from "shared/pages/loading";
import EventHub from "shared/services/Observer/EventHub";
import { CommanderInterface } from "game/services/CommanderService/CommanderServiceInterface";
import { CityInterface } from "game/services/CityService/CityServiceInterface";

interface GameProps {
  player: ArmyInterface;
  actor: CommanderInterface|null;
  commanders: CommanderInterface[];
  cities: CityInterface[];
  eventHub: EventHub;
  armyService: ArmyServiceInterface;
}

export default function Game({ player, actor,  commanders, cities, eventHub, armyService }: GameProps) {

  return (
    <>
      <GameMenu army={player} selected={actor?.id ?? ''} commanders={commanders} cities={cities} onActivate={(id: string) => armyService.activate(player)} />
      <Outlet />
    </>
  );
}
