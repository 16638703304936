import SubjectInterface from "./SubjectInterface";

export default abstract class AbstractSubject implements SubjectInterface {
    listeners:{[key: string]: CallableFunction[]} = {};

   on(event: string, handleEvent: CallableFunction): void {
    if (this.listeners[event] === undefined) this.listeners[event] = [];
    this.listeners[event].push(handleEvent);
   }

   off(event: string, handleEvent: CallableFunction): void {
    this.listeners[event] = this.listeners[event].filter((listener) => {
        if (listener !== handleEvent) {
          return true;
        }
        return false;
      });
   }

   emit(event: string, data: any){
       this.listeners[event]?.forEach((callback) => {
           callback(data);
       });
   }
}