import "./txt.css";

export default function Txt({ ...props }) {
  return <p className="txt">{props.children}</p>;
}

export function TxtMuted({ ...props }) {
  return <p className="txt txt-muted">{props.children}</p>;
}

export function TxtImportant({ ...props }) {
  return <p className="txt txt-important">{props.children}</p>;
}

export function TxtError({ ...props }) {
  return <p className="txt txt-error">{props.children}</p>;
}

export function Muted({ ...props }) {
  return <span className="txt-muted">{props.children}</span>;
}

export function Important({ ...props }) {
  return <span className="txt-important">{props.children}</span>;
}

export function AppTitle({ ...props }) {
  return <h1 className="title app-title neon-primary">{props.children}</h1>;
}

export function PageTitle({ ...props }) {
  return <h2 className={"title page-title" + (props.neon ? " neon-secondary" : "")}>{props.children}</h2>;
}

export function SectionTitle({ ...props }) {
  return <h3 className="title section-title">{props.children}</h3>;
}

export function ComponentTitle({ ...props }) {
  return <h4 className={"title component-title" 
    + (props.neon ? " neon" : "")
   }>{props.children}</h4>;
}
