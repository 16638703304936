import "./form.css";

import { TxtError, TxtMuted } from "../txt/txt";
import Loader from "../loader/loader";

export function Input({ ...props }) {
    return <input {...props} className="txt input" />;
}

export function Label({ ...props }) {
    return <label {...props}  className="label txt txt-muted">{props.children}</label>;
}

export default function Form({ ...props }) {
    return <form {...props} className="form">{props.children}</form>;
}

export function Field({ ...props }){
    return <div className="field">
        <Label htmlFor={props.name}>{props.label}</Label>
        <Input type={props.type} name={props.name} placeholder={props.placeholder} value={props.value} onChange={props.onChange}/>
        {props.help && <TxtMuted>props.help</TxtMuted>}
        {props.error && <TxtError>props.error</TxtError>}
    </div>
}

interface FormActionProps{
    submitting?: boolean;
    children?: any;
}

export function FormAction({ ...props }: FormActionProps) {
    if(props.submitting) return <footer className="form-action"><Loader/></footer>;
    return <footer className="form-action">{props.children}</footer>;
}

export function InfoSelector({ ...props }){
    return <section className="info-selector">
        {props.children}
    </section>
}