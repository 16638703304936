import * as React from "react";
import NationServiceInterface, { NationInterface } from "game/services/NationService/NationServiceInterface";
import { CommanderModelInterface } from "game/services/CommanderService/CommanderServiceInterface";
import { useNavigate } from "react-router-dom";
import ArmyServiceInterface, { EnrollRequest } from "game/services/ArmyService/ArmyServiceInterface";
import CommanderModelService from "game/services/CommanderService/CommanderModelService";
import PageContainer from "shared/components/atoms/container/container";
import EnrollForm from "game/components/organisms/form/enrollForm";

interface EnrollFormProps {
  armyService: ArmyServiceInterface;
  nationService: NationServiceInterface;
  commanderModelService: CommanderModelService;
}

export default function Enroll(
  { armyService, nationService, commanderModelService }: EnrollFormProps
) {
  const [nations, setNations] = React.useState<NationInterface[]>();
  const [commanderModels, setCommanderModels] = React.useState<CommanderModelInterface[]>();

  const navigate = useNavigate();

  const handleSubmit = async (formData: EnrollRequest) => {
    return armyService.enroll(formData)
      .then((response) => {
      navigate('/game');
    });
  };

  React.useEffect(() => {
    nationService.list().then(setNations);
  }, [nationService]);

  React.useEffect(() => {
    commanderModelService.list().then(setCommanderModels);
  }, [commanderModelService]);

  return (
    <PageContainer>
      <EnrollForm
        nations={nations}
        commanderModels={commanderModels}
        onSubmit={handleSubmit}
      />
    </PageContainer>
  );
}
