import StatsGrid from "game/components/atoms/stats";
import { UnitInterface, UnitModelInterface } from "game/services/CommanderService/CommanderServiceInterface";
import Txt, { Muted, TxtImportant, TxtMuted } from "shared/components/atoms/txt/txt";


interface UnitModelInfoProps {
    model: UnitModelInterface;
}

export default function UnitModelInfo({model}: UnitModelInfoProps){
    return <>
        <TxtImportant>{model.name}</TxtImportant>
        <TxtMuted>{model.description}</TxtMuted>
        <Txt>Caractéristiques</Txt>
        <StatsGrid stats={model.baseStats}/>
        <Txt>Traits</Txt>
        {model.attributes.length == 0 ? <TxtMuted>Aucun trait</TxtMuted> : null}
        {model.attributes.map((attribute) => {
            return <Txt key={attribute.id}>{attribute.name}{attribute.level > 0 ? "("+attribute.level+")" : null}: <Muted>{attribute.description}</Muted></Txt>
        })}
    </>;
}