import Bar from "game/components/atoms/bar/bar";
import Card, { CardBody, CardFoot, CardHead } from "shared/components/atoms/card"
import Txt, { Muted, TxtImportant } from "shared/components/atoms/txt/txt";
import { UnitInterface, UnitModelInterface } from "game/services/CommanderService/CommanderServiceInterface"
import { UnitIcon, UnitModelIcon } from "game/components/atoms/icons";
import StatsGrid from "game/components/atoms/stats";

interface UnitCardProps {
    unit: UnitInterface;

    selected?: any;
    onClick?: any;
}

export default function UnitCard(){
    return <></>
}


export function UnitIconCard({unit, selected, onClick}: UnitCardProps){
    return <Card size="sm" selected={selected} onClick={onClick}>
        <img 
            className="card-battleback"
            src={("/rsc/battlebacks/"+ unit.model.nationId + ".png").toLowerCase()}
        />
        <UnitIcon unit={unit}/>
        {(unit.wounds !== undefined) && 
        <CardFoot>
            <Bar 
            type="health" 
            size="sm" 
            progression={(unit.model.baseStats.health - unit.wounds)/unit.model.baseStats.health*100} 
            />
        </CardFoot>
        }
    </Card>
}

interface UnitModelCardProps {
    model: UnitModelInterface;

    selected?: any;
    onClick?: any;

    showStats?: boolean;
    showCosts?: boolean;
}

export function UnitModelCard({model, selected, onClick, showStats, showCosts}: UnitModelCardProps){
 return <Card size="md" selected={selected} onClick={onClick}>
    <UnitModelIcon model={model}/>
    <CardHead>
        <TxtImportant>{model.name}</TxtImportant>
    </CardHead>
    {showStats && <CardBody>
        <StatsGrid stats={model.baseStats}/>
    </CardBody>}
    {showCosts && <CardFoot>
        <Txt>{model.cost} <Muted>Or</Muted></Txt>
        <Txt>{model.upkeep} <Muted>Or/Tour</Muted></Txt>
    </CardFoot>}
 </Card>
}