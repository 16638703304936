import StatsGrid from "game/components/atoms/stats";
import { CommanderIconCard } from "game/components/organisms/commander";
import { UnitIconCard } from "game/components/organisms/unit";
import { CommanderInterface } from "game/services/CommanderService/CommanderServiceInterface";
import { Fragment } from "react";
import { CardContainer } from "shared/components/atoms/card";
import Crt from "shared/components/atoms/crt/crt";
import { InfoSelector } from "shared/components/atoms/form/form";
import { MetalPanel } from "shared/components/atoms/panel/panel";
import Pos from "shared/components/atoms/position";
import Txt, { Muted, TxtImportant } from "shared/components/atoms/txt/txt";

interface CommanderSquadSelectorProps {
    commander: CommanderInterface;
    selected: string;
    onSelect: (id: string) => void;
}

export default function CommanderSquadSelector({commander, selected, onSelect}: CommanderSquadSelectorProps){
    return <InfoSelector>
    <CardContainer>
    <CommanderIconCard commander={commander} selected={selected === commander.id} onClick={() => onSelect(commander.id)}/>
    {commander.units?.map((unit) => {
        return <UnitIconCard key={unit.id} unit={unit} selected={selected === unit.id} onClick={() => onSelect(unit.id)} />
    })}
    </CardContainer>
    <MetalPanel>
        <Crt>
            {
            (commander.id == selected) ? <>
                <TxtImportant>{commander.name}</TxtImportant>
                <Txt>{commander.model.name } - {commander.owner.name}</Txt>
                <Txt><Pos pos={commander.pos} /></Txt>
                {(commander.wounds !== undefined) ? <Txt>{commander.model.baseStats.health - commander.wounds}<Muted>Pv</Muted></Txt> : null}
                <StatsGrid stats={commander.model.baseStats}/>
            </> : null}
            {commander
                .units?.filter((unit) => unit.id == selected)
                .map((unit) => {
        return <Fragment key={unit.id}>
            <TxtImportant key={unit.id} >{unit.model.name}</TxtImportant>
            <Txt>{commander.owner.name}</Txt>
            {commander.pos && <Txt><Pos pos={commander.pos} /></Txt>}
            {(unit.wounds !== undefined) ? <Txt>{(unit.model.baseStats.health - unit.wounds)} <Muted>Pv</Muted></Txt> : null}
            <StatsGrid stats={unit.model.baseStats}/>
            </Fragment>
    })}
        </Crt>
    </MetalPanel>
</InfoSelector>;
}