import Btn from "shared/components/atoms/btn/btn";
import Form, { Field, FormAction } from "shared/components/atoms/form/form";
import Panel from "shared/components/atoms/panel/panel";
import { ComponentTitle, TxtError } from "shared/components/atoms/txt/txt";
import * as React from "react";

interface ChangePasswordFormProps {
  onSubmit?: any;
  error?: any;
  submitting: boolean;
}

export default function ChangePasswordForm({
  onSubmit,
  submitting = false,
  error
}: ChangePasswordFormProps) {
  const [formData, setFormData] = React.useState({
    password: "",
    repeat_password: "",
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!!onSubmit) onSubmit(formData);
  };

  return (
    <Panel>
      <ComponentTitle>Changer de mot de passe</ComponentTitle>
      {error && <TxtError>{error.message}</TxtError>}
    <Form onSubmit={handleSubmit}>
      <Field
        label="Mot de passe"
        type="password"
        placeholder="Mot de passe"
        name="password"
        value={formData.password}
        onChange={(e: any) => setFormData({ ...formData, password: e.target.value })}
        required
      />
      <Field
        label="Répétez le mot de passe"
        type="password"
        placeholder="Mot de passe"
        name="repeat_password"
        value={formData.repeat_password}
        onChange={(e: any) => setFormData({ ...formData, repeat_password: e.target.value })}
        required
      />
      <FormAction submitting={submitting}>
            <Btn type="submit">
              Changer
            </Btn>
      </FormAction>
    </Form>
    </Panel>
  );
}
