import Panel from "shared/components/atoms/panel/panel";
import CommanderServiceInterface, { CommanderInterface, CommanderModelInterface } from "game/services/CommanderService/CommanderServiceInterface";
import { useNavigate } from "react-router-dom";
import ArmyServiceInterface, { ArmyInterface } from "game/services/ArmyService/ArmyServiceInterface";
import Loader from "shared/components/atoms/loader/loader";
import Unauthorized from "shared/pages/unauthorized";
import PageContainer from "shared/components/atoms/container/container";
import Txt, { Important, Muted, PageTitle, TxtError, TxtMuted } from "shared/components/atoms/txt/txt";
import Form, { Field, FormAction } from "shared/components/atoms/form/form";
import Btn from "shared/components/atoms/btn/btn";
import CommanderModelSelector from "game/components/molecules/commander/commanderModelSelector";
import { useEffect, useState } from "react";
import Table from "shared/components/atoms/table/table";
import { CommanderIcon } from "game/components/atoms/icons";
import { Link } from "react-router-dom";

interface RecruitProps {
  player: ArmyInterface;
  commanders: CommanderInterface[];
  commanderService: CommanderServiceInterface;
  commanderModels: CommanderModelInterface[];
}

export default function Recruit(
  { player, commanders, commanderService, commanderModels }: RecruitProps
) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    modelId: "",
  });

  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if(formData.name.length < 4){
      setError({'message': 'Nom de commandant trop court'});
      return;
    }

    if(formData.name.length > 25){
      setError({'message': 'Nom de commandant trop long'});
      return;
    }

    if(formData.name.trim() !== formData.name){
      setError({'message': 'Les espaces sont interdits dans les noms de commandant'});
      return;
    }

    if(formData.modelId === ""){
      setError({'message': 'Sélectionnez un commandant'});
      return;
    }

    setLoading(true);
    commanderService.recruit(formData).then((response) => {
      navigate('/game');
    },
      (error) => {
        console.error(error);
        setError(error.response.data);
        setLoading(false);
      });
  };

  const inactiveCommanders = commanders.filter((commander) => !commander.pos);

  return (
    <PageContainer>
      <Panel>
        <PageTitle>Recruter</PageTitle>
        <Txt>Recruter un commandant coûte <Important>5.000 Or</Important> <Muted>({player.treasury} Or)</Muted></Txt>
        <Txt>Un commandant actif coûte <Important>500 Or par tour</Important></Txt>
        <TxtMuted>Vous ne pouvez avoir que 5 commandants actifs</TxtMuted>
        {error && (<TxtError>{error.message}</TxtError>)}
        <Form onSubmit={handleSubmit}>
          <Field
            label="Nom du commandant"
            type="text"
            name="name"
            value={formData.name}
            onChange={(e: any) =>
              setFormData({ ...formData, name: e.target.value })
            }
          />
          <CommanderModelSelector
            commanderModels={commanderModels
              .filter((model) => model.nationId === player.nation.id)}
            selected={formData.modelId}
            onSelect={(modelId: string) =>
              setFormData({
                ...formData,
                modelId: modelId,
              })
            }
          />

          <FormAction submitting={loading}>
            <Btn type="submit">
              Recruter ce commandant
            </Btn>
          </FormAction>
        </Form>
      </Panel>
      {
        (inactiveCommanders && inactiveCommanders.length > 0) ?
          <Panel>
            <PageTitle>Réserve</PageTitle>
            <Txt>Un commandant en réserve peut revenir pour 2.500 PO (Gratuit s'il s'agit de votre seigneur)</Txt>
            <TxtMuted>Vous ne pouvez avoir que 5 commandants actifs</TxtMuted>
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Nom</th>
                  <th>Classe</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {inactiveCommanders.map((commander: any) => {
                  return (
                    <tr key={commander.id}>
                      <td><CommanderIcon commander={commander} /></td>
                      <td><Link to={'/game/commanders/' + commander.id}>{commander.name}</Link></td>
                      <td>{commander.model.name}</td>
                      <td>
                        <Btn
                          onClick={() => {
                            commanderService
                              .respawn({commanderId: commander.id})
                              .then(() => navigate('/game'));
                          }}
                        >Revenir ({commander.id === player.lordId ? 0 : 2500} PO)</Btn>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Panel>
          : null
      }
    </PageContainer>

  );
}
