import { CityInterface } from "game/services/CityService/CityServiceInterface";
import { Link } from "react-router-dom";
import Crt from "shared/components/atoms/crt/crt";
import { MetalPanel } from "shared/components/atoms/panel/panel";
import Txt, { ComponentTitle, TxtImportant, TxtMuted } from "shared/components/atoms/txt/txt";

interface CityInfoPanelProps{
    city: CityInterface;
}

export default function CityInfoPanel({city}: CityInfoPanelProps){
    return <MetalPanel>
    <Crt>
        <ComponentTitle>
            {city.name}
        </ComponentTitle>
        <TxtMuted>{city.description}</TxtMuted>
        <Txt>Niveau {city.level}</Txt>
        <TxtImportant><Link className="link" to={'/game/cities/'+ city.id}>Voir ville</Link></TxtImportant>
    </Crt>
</MetalPanel>
}