import Panel from "../panel/panel";
import "./container.css"

export default function PageContainer({ ...props }) {
    return <div className="page-container">{props.children}</div>;
}

export function Row({ ...props }) {
    return <div className="row">{props.children}</div>;
}

export function CityGrid({...props}){
    return <div className="city-grid">
        {props.children}
    </div>
}

interface CityContainerProps{
    children?: any;
}
export function CityContainer({children}:CityContainerProps){
    return <div 
        className="city-container"
        style={
            {
                backgroundImage: "url('/rsc/tile/dirt.png')",
            }
        }
        >
        {children}
    </div>
}