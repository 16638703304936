import './stats.css';

import { Stats } from "game/services/CommanderService/CommanderServiceInterface";
import Txt, { Muted } from "shared/components/atoms/txt/txt";

interface StatsGridProps {
    stats: Stats;
}

export default function StatsGrid({stats}: StatsGridProps){
    return <div className="stats-grid">
        <Txt>{stats.health} <Muted>PV</Muted></Txt>
        <Txt>{stats.power} <Muted>PUI</Muted></Txt>
        <Txt>{stats.skill} <Muted>TEC</Muted></Txt>
        <Txt>{stats.speed} <Muted>VIT</Muted></Txt>
        <Txt>{stats.armor} <Muted>ARM</Muted></Txt>
        <Txt>{stats.magicResistance} <Muted>RM</Muted></Txt>
        <Txt>{stats.bravery} <Muted>BRV</Muted></Txt>
        <Txt>{stats.charisma} <Muted>CHA</Muted></Txt>
    </div>
}