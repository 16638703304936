import './intro.css';

export function GameboyScreen(props: any) {
    return (
      <div onClick={props.onClick} className="gameboy-screen-container">
        <div className="gameboy-screen">{props.children}</div>
      </div>
    );
  }
  


 export default function Intro({frame}: SceneProps) {
    return (
      <>
        {frame < 160 && <Scene01 frame={frame} />}
        {frame >= 160 && frame < 400 && <Scene02 frame={frame-160} />}
        {
          frame >= 400 &&
            <Scene03 frame={frame-400} />
        }
      </>
    );
  }

  interface SceneProps {
    frame: number;
  }
  
  function Scene01({frame}: SceneProps) {
    const txt = "Il y a longtemps...";
    
    const blastSize = Math.min(((frame-116)/2)**2, 480);
    
  
    return (
      <div
        className="scene"
        style={{
          opacity: Math.min(frame * 0.05, 1, (160-frame) * 0.05)
        }}
      >
        <img
          src="https://www.pixenli.com/image/JG3PkUdQ"
          style={{
            position: "absolute",
            left: "-" + Math.min(frame, 96) + "px"
          }}
        />
        { frame > 116 && (
          <img
            src="https://www.pixenli.com/image/8h1aZMwE"
            style={{
              position: "absolute",
              top: (80-blastSize/2)+"px",
              left: (77-blastSize/2)+"px",
              width: blastSize+"px",
                height: blastSize+"px"
            }}
          />
        )}
        <div className="txt-box">
          <p className="gb-txt">{txt.substring(0, frame / 4)}</p>
        </div>
      </div>
    );
  }
  
  function Scene02({frame}: SceneProps){
    const txt = "Le grand BOUM ravagea TERRA";
    
    return (
      <div className="scene" style={{
          opacity: Math.min(frame * 0.05, 1, (240-frame) * 0.05)
        }}>
        <img 
          src="https://www.pixenli.com/image/kl9ylvPN"
          style={
            {
              position: "absolute",
              bottom: "-"+Math.min(frame, 77)+"px"
            }
          }
          />
        <div className="txt-box">
          <p className="gb-txt">{txt.substring(0, frame / 4)}</p>
        </div>
        </div>
    );
  }
  
  function Scene03({frame}: SceneProps){
    const txt = "Depuis le monde est un désert stérile";
    return <div 
             className="scene"
        style={{
          opacity: Math.min(frame * 0.05, 1, (200-frame) * 0.05)
        }}>
      <img
        src="https://www.pixenli.com/image/AdyAMjbh"
        style={{
            position: "absolute",
            right: "-" + Math.min(frame/2, 160) + "px"
          }}
        />
      <div className="txt-box">
          <p className="gb-txt">{txt.substring(0, frame / 4)}</p>
        </div>
      </div>;
  }
  
  function TitleScreen(){
    return (
       <div className="scene">
        <h3 className="gameboy-title">ZON2K20</h3>
        <p className="gameboy-press-start">PRESS START</p>
        </div>
    );
  }