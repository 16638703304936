import * as React from "react";
import Panel from "shared/components/atoms/panel/panel";

export default function Forbidden() {
  return (
    <Panel>
      <h2 className="title">Forbidden</h2>
    </Panel>
  );
}
