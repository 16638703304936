import Actorbar from "game/components/board/actorbar";
import Board from "game/components/organisms/board/board";
import Panel, { MetalPanel } from "shared/components/atoms/panel/panel";
import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import ArmyServiceInterface, { ActivateArmyTurnResponse, ArmyInterface, LogInterface, ReportInterface } from "game/services/ArmyService/ArmyServiceInterface";
import CommanderServiceInterface, { CommanderInterface } from "game/services/CommanderService/CommanderServiceInterface";
import MapServiceInterface, { ViewInterface } from "game/services/MapService/MapServiceInterface";
import MoveServiceInterface, { MoveInterface, Path } from "game/services/MoveService/MoveServiceInterface";
import { TileInterface } from "game/services/TileService/TileService";
import Txt, { ComponentTitle, Muted, PageTitle, TxtImportant, TxtMuted } from "shared/components/atoms/txt/txt";
import PageContainer, { Row } from "shared/components/atoms/container/container";
import PageLoading from "shared/pages/loading";
import { Position } from "utils/Position";
import Pos from "shared/components/atoms/position";
import { CommanderSquadInfoPanel } from "./commander";
import EventHub from "shared/services/Observer/EventHub";
import terrainCost from "game/services/TerrainService/TerrainServiceInterface";
import CityInfoPanel from "game/components/organisms/city";
import { report } from "process";
import { CityInterface } from "game/services/CityService/CityServiceInterface";
import path from "path";

interface ViewProps {
    actor: CommanderInterface | null;
    reports: ReportInterface[];
    tiles: TileInterface[];
    commanders: CommanderInterface[];
    cities: CityInterface[];
    eventHub: EventHub;
    armyId: string;
    commanderService: CommanderServiceInterface;
    mapService: MapServiceInterface;
    moveService: MoveServiceInterface;
    refreshView: (id: string, x: number, y: number) => void;
    selectActor: (id: string) => void;
}
const RADIUS = 9;

export default function View({ actor, reports, tiles, commanders, cities, armyId, eventHub, commanderService, moveService, refreshView, selectActor }: ViewProps) {
    const navigate = useNavigate();
    const { id, x, y } = useParams();
    const [view, setView] = useState<ViewInterface>();
    const [origin, setOrigin] = useState<Position>({ x: parseInt(x ?? "0") - RADIUS, y: parseInt(y ?? "0") - RADIUS });

    const [moveMode, setMoveMode] = useState<boolean>(true);

    const [pathSelected, setPathSelected] = useState<Path>();
    const [tileSelected, setTileSelected] = useState<TileInterface>();

    const [paths, setPaths] = useState<Path[]>([]);

    const [error, setError] = useState<string | null>(null);

    const [actions, setActions] = useState<any[]>([]);

    useEffect(() => {
        if (id)
            setView({
                map: { id: id },
                tiles: tiles,
                commanders: commanders,
                cities: cities
            });
    }, [id, tiles, commanders, cities]);

    useEffect(() => {
        if (id == undefined || x == undefined || y == undefined) {
            return;
        }

        refreshView(id, parseInt(x), parseInt(y));
        const startPos = origin;
        const endPos = { x: parseInt(x) - RADIUS, y: parseInt(y) - RADIUS };
        requestAnimationFrame((current) =>
            cameraMove(current, current, 500, 24, startPos, endPos)
        );

        const actor = commanders.find((commander) => commander.pos?.x === parseInt(x ?? '0') && commander.pos?.y === parseInt(y ?? '0') && commander.owner.id === armyId);
        if (actor) selectActor(actor.id);

        const tileSelected = tiles.find((tile) => tile.pos.x === parseInt(x ?? '0') && tile.pos.y === parseInt(y ?? '0'));
        if (tileSelected) setTileSelected(tileSelected);

    }, [id, x, y]);

    useEffect(() => {
        const actor = commanders.find((commander) => commander.pos?.x === parseInt(x ?? '0') && commander.pos?.y === parseInt(y ?? '0') && commander.owner.id === armyId);
        if (actor) selectActor(actor.id);
    }, [id, x, y, commanders] )

    useEffect(() => {
        if (actor && view) setPaths(moveService.calcPaths(actor, view));
    }, [actor, view, moveService]);

    const cameraMove = (debut: number, current: number, duration: number, fps: number, startPos: Position, endPos: Position) => {
        if (view === undefined) return;

        const time = current - debut;
        const nbFrame = Math.ceil(fps * time / 1000);
        const totalFrames = Math.ceil(fps * duration / 1000);

        if (nbFrame > totalFrames) {
            setOrigin(endPos);
            return;
        }
        const deltaXPerFrame = (endPos.x - startPos.x) / (fps * duration / 1000);
        const deltaYPerFrame = (endPos.y - startPos.y) / (fps * duration / 1000);

        setOrigin({
            x: startPos.x + (deltaXPerFrame * (nbFrame)),
            y: startPos.y + (deltaYPerFrame * (nbFrame))
        });

        requestAnimationFrame((current) =>
            cameraMove(debut, current, duration, fps, startPos, endPos)
        );
    };

    const commanderMultiMove = (moves: MoveInterface[]) => {
        if (moves.length == 0)
            return;
        const duration = 1000 / moves.length;
        moves.map((move, index) => {
            setTimeout(() => {
                requestAnimationFrame((current) =>
                    commanderMove(current, current, duration, 24, move)
                );
            }, duration * index)
        })
    }

    /*
    const commanderMove = (debut: number, current: number, duration: number, fps: number, move: MoveInterface) => {
        if (view === undefined) return;

        const time = current - debut;
        const nbFrame = Math.ceil(fps * time / 1000);
        const totalFrames = Math.ceil(fps * duration / 1000);

        if (nbFrame > totalFrames) {

            setView((view) => {
                if (view !== undefined)
                    return {
                        ...view, elements: view?.elements.map((element) => {
                            if (element.id == move.actorId)
                                return {
                                    ...element,
                                    pos: move.end
                                }
                            return element;
                        }
                        )
                    }
            });
            return;
        }
        const deltaXPerFrame = (move.end.x - move.start.x) / (fps * duration / 1000);
        const deltaYPerFrame = (move.end.y - move.start.y) / (fps * duration / 1000);

        setView((view) => {
            if (view !== undefined)
                return {
                    ...view, elements: view?.elements.map((element) => {
                        if (element.id == move.actorId)
                            return {
                                ...element,
                                pos: {
                                    x: move.start.x + (deltaXPerFrame * (nbFrame)),
                                    y: move.start.y + (deltaYPerFrame * (nbFrame))
                                }
                            }
                        return element;
                    }
                    )
                }
        });

        requestAnimationFrame((current) =>
            commanderMove(debut, current, duration, fps, move)
        );
    };
    */

    const commanderMove = async (debut: number, current: number, duration: number, fps: number, move: MoveInterface) => {
        if (view === undefined) return;

        const time = current - debut;
        const nbFrame = Math.ceil(fps * time / 1000);
        const totalFrames = Math.ceil(fps * duration / 1000);

        if (nbFrame > totalFrames) {

            setView((view) => {
                if (view !== undefined)
                    return {
                        ...view, elements: view?.commanders.map((element) => {
                            if (element.id == move.actorId)
                                return {
                                    ...element,
                                    pos: move.end
                                }
                            return element;
                        }
                        )
                    }
            });
            return;
        }
        const deltaXPerFrame = (move.end.x - move.start.x) / (fps * duration / 1000);
        const deltaYPerFrame = (move.end.y - move.start.y) / (fps * duration / 1000);

        setView((view) => {
            if (view !== undefined)
                return {
                    ...view, commanders: view?.commanders.map((element) => {
                        if (element.id == move.actorId)
                            return {
                                ...element,
                                pos: {
                                    x: move.start.x + (deltaXPerFrame * (nbFrame)),
                                    y: move.start.y + (deltaYPerFrame * (nbFrame))
                                }
                            }
                        return element;
                    }
                    )
                }
        });

        await new Promise(resolve => {
            requestAnimationFrame(async (current) => {
                await commanderMove(debut, current, duration, fps, move);
                resolve(move);
            }
            );
        });
    };

    useEffect(() => {
        if (actor && tileSelected){
            const path = paths.find((path) => tileSelected && (path.last().id === tileSelected.id));
            setPathSelected(path)
            commanderService
                .listActions({
                    commanderId: actor.id,
                    pos: tileSelected.pos,
                    options: {path: JSON.stringify(path?.nodes.map((tile) => tile.pos)) } 
                })
                .then(setActions);
        }
    }, [actor, paths, tileSelected]);

    if (!view) return <PageLoading />;
    if(!id || !x || !y) return <></>;

    return (<PageContainer>
        <div className="view-container">
            <Board
                actor={actor}
                view={view}
                paths={paths}
                tileSelected={tileSelected ?? null}
                pathSelected={pathSelected}
                origin={origin}
                onTileSelect={(tile: TileInterface) => {
                    setTileSelected(tile);

                    const actor = commanders.find((commander) => commander.pos?.x === tile.pos.x && commander.pos?.y === tile.pos.y && commander.owner.id === armyId);
                    if (actor) selectActor(actor.id);

                    if(tile === tileSelected) {
                        const actor = commanders.find((commander) => commander.pos?.x === tile.pos.x && commander.pos?.y === tile.pos.y && commander.owner.id === armyId);
                        if (actor) selectActor(actor.id);
                        navigate('/game/view/' + id + '/' + tile.pos.x + '/' + tile.pos.y);
                    }
                }}
                moveMode={moveMode}
            />
            <div className="action-menu">
                {actor && <Actorbar
                    actor={actor}
                    error={error}
                    moveMode={moveMode}
                    paths={paths}
                    onMove={() => setMoveMode((mode) => !mode)}
                    actions={actions}
                    handleAction={(actionId: string) => {
                        if(!tileSelected) return;
                        commanderService.useAction({ 
                            commanderId: actor.id, 
                            pos: tileSelected.pos, 
                            actionId: actionId,
                            options: {path: JSON.stringify(pathSelected?.nodes.map((tile) => tile.pos)) } 
                        })
                        id && refreshView(id, tileSelected.pos.x, tileSelected.pos.y)
                        
                    }}
                />}
                {tileSelected &&
                    <Panel>
                        <ComponentTitle neon={true}>{tileSelected.terrain.name}</ComponentTitle>
                        <TxtMuted><Pos pos={tileSelected.pos} /></TxtMuted>
                    </Panel>
                }

                {view
                    .commanders
                    .filter((element) => element.pos?.x === parseInt(x ?? "0") && element.pos?.y === parseInt(y ?? "0"))
                    .map((element) => <CommanderSquadInfoPanel key={element.id} commander={element} />
                    )
                }

                {view
                    .cities
                    .filter((element) => element.pos?.x === parseInt(x ?? "0") && element.pos?.y === parseInt(y ?? "0"))
                    .map((element) => <CityInfoPanel city={element} />
                    )
                }
            </div>
        </div>
        <Panel>
            <ComponentTitle neon={true}>Historique</ComponentTitle>
            <table><tbody>
                {reports.map((report: ReportInterface) => {
                    return <tr key={report.id}>
                        <td>
                            <TxtImportant>{new Date(report.when).toLocaleDateString("fr", {
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                            })}</TxtImportant>
                        </td>
                        <td>
                            {report.logs.map((log: LogInterface) => {
                                switch (log.type) {
                                    case 'TYPE_MOVE':
                                        return <Txt key={log.id}>{log.data.commander?.name} se déplace de <Pos pos={log.data.start} /> à <Pos pos={log.data.end} /></Txt>
                                    default:
                                        return <Txt key={log.id}>Log inconnu</Txt>
                                }
                            })}
                        </td>
                    </tr>
                }).reverse()}</tbody></table>
        </Panel>


    </PageContainer>);

}