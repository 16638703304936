import { UnitModelIcon } from "game/components/atoms/icons";
import { UnitModelInterface } from "game/services/CommanderService/CommanderServiceInterface";
import Crt from "shared/components/atoms/crt/crt";
import { InfoSelector } from "shared/components/atoms/form/form";
import Loader from "shared/components/atoms/loader/loader";
import { MetalPanel } from "shared/components/atoms/panel/panel";
import Table from "shared/components/atoms/table/table";
import Txt, { TxtImportant, TxtMuted } from "shared/components/atoms/txt/txt";
import UnitModelInfo from "./unitModelInfo";

interface UnitSelectorProps {
    unitModels?: UnitModelInterface[];
    selected?: string;
    onSelect?: any;
}

export default function UnitModelSelector({unitModels, selected, onSelect}: UnitSelectorProps){
    const selectedUnit = unitModels?.find((unitModel) => unitModel.id === selected);
    if(!unitModels) return <Loader/>;
    if(unitModels.length == 0) return <TxtMuted>Aucune unité</TxtMuted>;

    return <InfoSelector>
        <Table>
          <tbody>
        {unitModels.map((unitModel) => {
              return (
                <tr className={
                    "table-row" +
                    (unitModel.id === selected ? " selected" : "")
                  } key={unitModel.id} onClick={() => onSelect(unitModel.id)}> 
                <td>
                    <UnitModelIcon model={unitModel} />
                </td>
                <td>
                    <TxtImportant>{unitModel.name}</TxtImportant>
                </td>
                  <td>
                    <Txt>{unitModel.cost} Or</Txt>
                    <TxtMuted>{unitModel.upkeep} Or/Tour</TxtMuted>
                  </td>
                  <td>
                    <Txt>{unitModel.baseStats.charisma}&nbsp;Cha</Txt>
                  </td>
                </tr>
              );
            })}
            </tbody>
        </Table>
        <MetalPanel>
            <Crt>
                {selectedUnit && <UnitModelInfo model={selectedUnit}/>}
            </Crt>
        </MetalPanel>
    </InfoSelector>
}