export interface Position {
  x: number;
  y: number;
}

export function calcDist(posA: Position, posB: Position) {
  //return calcDistEuclide(posA, posB);
  //*
  const distX = Math.abs(posA.x - posB.x);
  const distY = Math.abs(posA.y - posB.y);
  const line = Math.max(distX, distY);
  const diag = Math.min(distX, distY) / 2;

  return line + diag;
  //*/
}

export function isDiagonal(posA: Position, posB: Position) {
  return Math.abs(posA.x - posB.x) > 0 && Math.abs(posA.y - posB.y) > 0;
}

export function calcDistEuclide(posA: Position, posB: Position) {
  return Math.sqrt(Math.pow(posB.x - posA.x, 2) + Math.pow(posB.y - posA.y, 2));
}

export function calcAngle(posA: Position, posB: Position, posC: Position) {
  const a = calcDistEuclide(posB, posC);
  const b = calcDistEuclide(posA, posC);
  const c = calcDistEuclide(posB, posA);

  let value = (a * a + c * c - b * b) / (2 * a * c);
  //value = Math.min(1, Math.max(-1, value));

  return Math.round((180 * Math.acos(value)) / Math.PI);
}
