import AuthService from "shared/services/AuthService/AuthService";
import AbstractSubject from "shared/services/Observer/AbstractSubject";
import httpCommon from "utils/http-common";
import BuildingModelServiceInterface, { BuildingModelQuery } from "./BuildingServiceInterface";

const URL = "/game/building-models/";

export default class BuildingModelService extends AbstractSubject implements BuildingModelServiceInterface
{
  authService: AuthService;

  constructor(authService: AuthService) {
    super();
    this.authService = authService;
  }

  async list(query?: BuildingModelQuery) {
    try {
      const response = await httpCommon.get(URL, {
        headers: this.authService.authHeader(),
        params: query
      });
      return response.data.buildings;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        this.authService.logout();
      }
      throw error;
    }
  }

  async get(id: string) {
    try {
      const response = await httpCommon.get(URL + id, {
        headers: this.authService.authHeader(),
      });
      return response.data.building;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        this.authService.logout();
      }
      throw error;
    }
  }
}
