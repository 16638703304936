import * as React from "react";

import Panel from "shared/components/atoms/panel/panel";
import { useNavigate } from "react-router-dom";
import AuthServiceInterface, { LoginRequest } from "shared/services/AuthService/AuthServiceInterface";
import { PageTitle, TxtError, TxtMuted } from "shared/components/atoms/txt/txt";
import LoginForm from "game/components/organisms/form/loginForm";
import PageContainer from "shared/components/atoms/container/container";
import { Link } from "react-router-dom";

interface LoginFormProps {
  authService: AuthServiceInterface
}

export default function Login({ authService }: LoginFormProps) {
  const navigate = useNavigate();

  const [error, setError] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSubmit = (formData: LoginRequest) => {
    setLoading(true);
    authService.login(formData).then((response) => {
      navigate('/game');
    },
      (error) => {
        setError(error);
        setLoading(false);
      });
  };

  if(authService.isAuth()) {
    return <></>;
  }

  return (
    <PageContainer>
      <Panel>
        <PageTitle>Connexion</PageTitle>
        {error && <TxtError>Nom d'utilisateur ou mot de passe incorrect</TxtError>}
        <LoginForm onSubmit={handleSubmit} submitting={loading}></LoginForm>
        <TxtMuted><Link to="/forget-password">Mot de passe oublié ?</Link></TxtMuted>
      </Panel>
    </PageContainer>
  );
}
