import Btn from "shared/components/atoms/btn/btn";
import Form, { Field, FormAction } from "shared/components/atoms/form/form";
import { useState } from "react";
import Txt from "shared/components/atoms/txt/txt";


interface ForgetPasswordFormProps {
  onSubmit?: any;
  submitting: boolean;
}

export default function ForgetPasswordForm({
  onSubmit,
  submitting = false,
}: ForgetPasswordFormProps) {
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!!onSubmit) onSubmit(formData);
  };

  return (

    <Form onSubmit={handleSubmit}>
      <Txt>Un mail de récupération sera envoyé à l'adresse mail suivante si un compte existe</Txt>
      <Field
        label="Email"
        className="field"
        type="text"
        placeholder="Email"
        name="username"
        value={formData.email}
        onChange={(e: any) => setFormData({ ...formData, email: e.target.value })}
        required
      />
      <FormAction submitting={submitting}>
            <Btn type="submit">
              Envoyer
            </Btn>
      </FormAction>
    </Form>

  );
}