import "./topnav.css";

import { Component, ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import AuthServiceInterface from "shared/services/AuthService/AuthServiceInterface";
import Btn from "shared/components/atoms/btn/btn";
import { Important } from "shared/components/atoms/txt/txt";
import NavCollapse from "shared/components/atoms/collapse";

interface TopnavProps{
  authService: AuthServiceInterface;
}

export default function Topnav({authService}: TopnavProps){
  const [collapse, setCollapse] = useState<boolean>(true);

    return (
      <div className="topnav">
        <Link to="/" className="txt nav-item primary">
          <Important>Zon2k20</Important>
        </Link>
        <Btn className={"topnav-btn-menu"} onClick={() => setCollapse((value) => !value )}>MENU</Btn>
        <NavCollapse collapse={collapse}>
        {authService.isAuth() ?
        <>
          <Link className="txt nav-item primary" to="/game">
            Jeu
          </Link>
          <Link to={"/game/profile"} className="txt nav-item primary">
            Options
          </Link>
          <Link className="txt nav-item primary" to="/logout">
            Déconnexion
          </Link>
          </> : 
          <>
          <Link className="txt nav-item primary" to="/login">
            Connexion
          </Link>
          </>
          }
        </NavCollapse>
      </div>
    );
  }

/*
<!-- Top Navigation Menu -->
<div class="topnav">
  <a href="#home" class="nav-item primary" data-collapse="#topnav-menu" onclick="collapse(this)">Zon2k20</a>
  <!-- Navigation links (hidden by default) -->
  <div class="nav collapse" id="topnav-menu">
    <a class="nav-item secondary" href="#news">News</a>
    <a class="nav-item secondary" href="#contact">Contact</a>
    <a class="nav-item secondary" href="#about">About</a>
  </div>
</div>
*/
