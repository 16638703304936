import { MouseEventHandler } from "react";

interface SpriteProps {
    className?: string,
    image: string,
    data: {
        x: number,
        y: number,
        w: number,
        h: number
    },
    position: {
        x: number,
        y: number,
        z?: number
    },
    onClick?: MouseEventHandler|undefined;
}

export default function Sprite({className=undefined, image, data, position, onClick}: SpriteProps) {
    const { x, y, w, h } = data;
    return (
        <div
            className={className}
            onClick={onClick}
            style={{
                position: "absolute",
                bottom: position.y,
                left: position.x,
                zIndex: position.z ?? 0,
                height: `${h}px`,
                width: `${w}px`,
                pointerEvents: onClick == null ? "none" : "auto",
                backgroundImage: `url(${image})`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `${w}px ${h}px`,
                backgroundPosition: `-${x}px -${y}px`
            }}
        ></div>
    );
}
