import Crt from "shared/components/atoms/crt/crt";
import { InfoSelector } from "shared/components/atoms/form/form";
import Loader from "shared/components/atoms/loader/loader";
import { MetalPanel } from "shared/components/atoms/panel/panel";
import Table from "shared/components/atoms/table/table";
import Txt, { Important, Muted, TxtImportant, TxtMuted } from "shared/components/atoms/txt/txt";
import { BuildingModelInterface } from "game/services/BuildingService/BuildingServiceInterface";
import { BuildingModelIcon } from "game/components/atoms/icons";
import { UnitModelInterface } from "game/services/CommanderService/CommanderServiceInterface";
import { UnitModelCard } from "game/components/organisms/unit";
import { CardContainer } from "shared/components/atoms/card";

interface BuildingSelectorProps {
    buildingModels?: BuildingModelInterface[];
    selected?: string;
    onSelect?: any;
}

export default function BuildingModelSelector({buildingModels, selected, onSelect}: BuildingSelectorProps){
    const selectedBuilding = buildingModels?.find((buildingModel) => buildingModel.id === selected);
    if(!buildingModels) return <Loader/>;
    if(buildingModels.length == 0) return <TxtMuted>Aucun bâtiment</TxtMuted>;

    return <InfoSelector>
        <Table>
          <tbody>
        {buildingModels.map((buildingModel) => {
              return (
                <tr className={
                    "table-row" +
                    (buildingModel.id === selected ? " selected" : "")
                  } key={buildingModel.id} onClick={() => onSelect(buildingModel.id)}> 
                <td>
                    <BuildingModelIcon model={buildingModel} />
                </td>
                <td>
                    <TxtImportant>{buildingModel.name}</TxtImportant>
                </td>
                  <td>
                    <Txt>{buildingModel.cost} Or</Txt>
                  </td>
                </tr>
              );
            })}
            </tbody>
        </Table>
        <MetalPanel>
            <Crt>
                {selectedBuilding && <BuildingModelInfo model={selectedBuilding}/>}
            </Crt>
        </MetalPanel>
    </InfoSelector>
}

interface BuildingModelInfoProps{
  model: BuildingModelInterface;
}

export function BuildingModelInfo({model}: BuildingModelInfoProps){
  return <>
  <TxtImportant>{model.name}</TxtImportant>
  <TxtMuted>Niveau {model.level}</TxtMuted>
  <TxtMuted>{model.description}</TxtMuted>
  {model.previous ? <Txt>Améliore <Important>{model.previous.name}</Important></Txt> : null}
  {model.effects.INCOME ? <Txt>+{model.effects.INCOME} <Muted>Or/Tour</Muted></Txt> : null}
    {model.effects.RECRUIT &&
    <div>
      <TxtMuted>Permet le recrutement de :</TxtMuted>
      <CardContainer>
      {model.effects.RECRUIT.map((model: UnitModelInterface) => {
        return <UnitModelCard key={model.id} model={model}/>
      })}</CardContainer>
    </div>
    }
</>
}