import Btn, { BtnBar } from "shared/components/atoms/btn/btn";
import PageContainer from "shared/components/atoms/container/container";
import Loader from "shared/components/atoms/loader/loader";
import Panel from "shared/components/atoms/panel/panel";
import Table from "shared/components/atoms/table/table";
import Txt, { ComponentTitle, Important, Muted, PageTitle } from "shared/components/atoms/txt/txt";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArmyServiceInterface, { ArmyInterface, calcIncome, calcUpkeep } from "game/services/ArmyService/ArmyServiceInterface";
import { BuildingIcon, CityIcon, CommanderIcon } from "game/components/atoms/icons";
import { FormAction } from "shared/components/atoms/form/form";
import EventHub from "shared/services/Observer/EventHub";
import { CityInterface } from "game/services/CityService/CityServiceInterface";
import Pos from "shared/components/atoms/position";
import PageLoading from "shared/pages/loading";
import NotFound from "shared/pages/notFound";
import { CommanderInterface } from "game/services/CommanderService/CommanderServiceInterface";

interface GameOverviewProps {
    player: ArmyInterface;
    commanders : CommanderInterface[];
    cities: CityInterface[];
    eventHub: EventHub;
    armyService: ArmyServiceInterface;
}

export default function GameOverview({ player, commanders, cities, eventHub, armyService }: GameOverviewProps) {
    const [activate, setActivate] = useState<boolean>(false);

    const activeCommanders = commanders.filter((commander) => !!commander.pos);
    return (
        <PageContainer>
            <Panel>
                <PageTitle neon={true}>{player.name}</PageTitle>
                <Txt>Armée des {player.nation.name}</Txt>
                <Txt>{player.treasury} <Muted>PO</Muted> <Important>{calcIncome(player, commanders, cities) - calcUpkeep(player, commanders, cities)} Or/Tour</Important> </Txt>
                <Txt><Muted>Prochain tour le :</Muted> {player.nextTurn}</Txt>
                <FormAction submitting={activate}>
                    <Btn onClick={
                        () => {
                            setActivate(true)
                            armyService.activate(player).finally(() => setActivate(false));
                        }
                    }>Activer</Btn>
                </FormAction>
                <Table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Nom</th>
                            <th>Classe</th>
                            <th>Pos</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {commanders.map((commander: any) => {
                            return (
                                <tr key={commander.id}>
                                    <td><CommanderIcon commander={commander} /></td>
                                    <td><Link to={'/game/commanders/' + commander.id}>{commander.name}</Link></td>
                                    <td>{commander.model.name}</td>
                                    <td>
                                        [{commander.pos.x};{commander.pos.y}]
                                    </td>
                                    <td>
                                        <Link to={'/game/view/' + commander.mapId + '/' + commander.pos.x + '/' + commander.pos.y}>Voir</Link>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

                <Table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Nom</th>
                            <th>Type</th>
                            <th>Pos</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {cities.map((city: CityInterface) => {
                            return (
                                <tr key={city.id}>
                                    <td>
                                        <CityIcon city={city}/>
                                        {city.buildings?.map((building, index) => <BuildingIcon key={index} building={building} />)}
                                    </td>
                                    <td><Link to={'/game/cities/' + city.id}>{city.name}</Link></td>
                                    <td>{city.type} niveau {city.level} </td>
                                    <td>
                                        <Pos pos={city.pos}/>
                                    </td>
                                    <td>
                                        {city.mapId && city.pos && <Link to={'/game/view/' + city.mapId + '/' + city.pos.x + '/' + city.pos.y}>Voir</Link>}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                
                <BtnBar>
                    {((activeCommanders.length ?? 0) < 5) ? <Btn>
                        <Link to={"/game/commanders/recruit"}>Recruter</Link>
                    </Btn> : ''}
                </BtnBar>

            </Panel>
        </PageContainer>

    );
}