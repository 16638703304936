import { Position } from "utils/Position";

interface PosProps {
    pos?: Position;
}

export default function Pos({pos}: PosProps){
    if(!pos) return <></>;
    return <>
        [{pos.x};{pos.y}]
    </>;
}