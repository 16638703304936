import { ArmyInterface } from "game/services/ArmyService/ArmyServiceInterface";
import { CommanderInterface } from "game/services/CommanderService/CommanderServiceInterface";
import { Path } from "game/services/MoveService/MoveServiceInterface";
import Btn from "shared/components/atoms/btn/btn";
import { FormAction } from "shared/components/atoms/form/form";
import Panel from "shared/components/atoms/panel/panel";
import Pos from "shared/components/atoms/position";
import Txt, { ComponentTitle, Muted, TxtError, TxtMuted } from "shared/components/atoms/txt/txt";

interface ActorbarProps {
    actor: CommanderInterface;
    paths: Path[];
    moveMode: boolean;
    onMove: CallableFunction;
    error: string|null;
    actions: any[];
    handleAction: any;
}

export default function Actorbar({ actor, paths, moveMode, onMove, error, actions, handleAction }: ActorbarProps) {

    const executableActions = actions.filter((action) => action.canExecute);

    return <Panel>        
        <ComponentTitle neon={true}>{actor.name}</ComponentTitle>
        <Txt>{actor.ap} <Muted>PA</Muted></Txt>
        {error && <TxtError>{error}</TxtError>}

        {executableActions.length > 0  ? <Txt>Actions possibles : </Txt> : <TxtMuted>Aucune action possible...</TxtMuted>}
        <FormAction>
             {executableActions.map((action) => <Btn disabled={!action.canPay} key={action.id} onClick={() => handleAction(action.id)}>{action.name} ({Object.keys(action.actorCosts).map(
                (keyName, keyIndex) => action.actorCosts[keyName] +' '+ keyName

             )})</Btn>)}
             
        </FormAction>
    </Panel>
}