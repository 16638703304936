import PageContainer from "shared/components/atoms/container/container";
import Crt from "shared/components/atoms/crt/crt";
import Form, { FormAction } from "shared/components/atoms/form/form";
import Panel, { MetalPanel } from "shared/components/atoms/panel/panel";
import Txt, { ComponentTitle, Important, PageTitle, TxtError, TxtMuted } from "shared/components/atoms/txt/txt";
import { UnitModelCard } from "game/components/organisms/unit";
import PageLoading from "shared/pages/loading";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BuildingInterface, BuildingModelInterface } from "game/services/BuildingService/BuildingServiceInterface";
import CityServiceInterface, {CityInterface } from "game/services/CityService/CityServiceInterface";
import CommanderServiceInterface, { CommanderInterface, UnitModelInterface, UnitServiceInterface } from "game/services/CommanderService/CommanderServiceInterface";
import { calcDist } from "utils/Position";
import { CardContainer } from "shared/components/atoms/card";
import Btn from "shared/components/atoms/btn/btn";
import BuildingModelSelector from "game/components/molecules/building/buildingModelSelector";
import BuildingSelector from "game/components/molecules/building/buildingSelector";
import CommanderSelector from "game/components/molecules/commander/commanderSelector";
import UnitModelSelector from "game/components/molecules/unit/unitModelSelector";
import { AxiosError } from "axios";

export default function Cities() {
    return <PageContainer></PageContainer>;
}

interface CityPageProps {
    commanders: CommanderInterface[]
    cityService: CityServiceInterface;
    unitService: UnitServiceInterface;
}

export function CityPage({ commanders, cityService, unitService }: CityPageProps) {
    const { id } = useParams();
    const [city, setCity] = useState<CityInterface>();
    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    useEffect(() => {
        id && cityService.get(id).then(setCity);
    }, [id, cityService]);

    if (city === undefined) return <PageLoading />;
    if (!city ||!id) return <div>Error</div>;

    return <PageContainer>
        <MetalPanel>
            <Crt>
                <PageTitle>
                    {city.name}
                </PageTitle>
                <TxtMuted>{city.description}</TxtMuted>
                <Txt>Niveau {city.level}</Txt>
                {city.canBeUpgraded ? <FormAction submitting={isSubmitting}>
                    <Btn onClick={() => {
                        setSubmitting(true);
                        cityService.upgradeCity({cityId: city.id})
                        .then(() => cityService.get(city.id).then(setCity))
                        .finally(() => setSubmitting(false));
                    }}>Améliorer la ville ({city.upgradeCost} Or)</Btn>
                </FormAction> : null}
            </Crt>
        </MetalPanel>
        {city.buildings && <ConstructedPanel 
            buildings={city.buildings} 
            handleDelete={(modelId: string) => {
            cityService
                .destroyBuilding({ cityId: city.id, modelId: modelId })
                .then(
                    () => { id && cityService.get(id).then(setCity) }
                );
        }} />}
        {city.canBuild
            && <BuildingPanel 
                models={city.canBuild} 
                openSlots={city.openSlots ?? 0}
                buildingSlots={city.buildingSlots ?? 0}
                submitting={isSubmitting} 
                onSubmit={(data: any) => {
                setSubmitting(true);
                return cityService.build({ ...data, cityId: city.id })
                    .then(
                        () => { 
                            id && cityService.get(id).then(setCity);
                        }
                    )
                    .catch((error) => console.error(error))
                    .finally(() => setSubmitting(false));
            }} />}
        {
            city.canRecruit
            && commanders
            && <RecruitingPanel 
                recruitCost={city.type === "CAMP" ? 6 : 4}
                commanders={commanders.filter((commander) => (city.pos && commander.pos) ? (calcDist(city.pos, commander.pos) < 2) : !!(commander.pos))} 
                models={city.canRecruit}
                submitting={isSubmitting}
                onSubmit={async (data: any) => {
                    setSubmitting(true);
                    try{
                        await unitService.recruit({ ...data, cityId: city.id })
                        id && cityService.get(id).then(setCity);
                        setSubmitting(false);
                        return;
                    } catch(error: any) {
                        setSubmitting(false)
                        throw error;
                    }
                }}
                 />
        }
    </PageContainer>;
}

interface ConstructedPanelProps {
    buildings: BuildingInterface[];
    handleDelete: any;
}

function ConstructedPanel({ buildings, handleDelete }: ConstructedPanelProps) {
    const [selected, setSelected] = useState<string>();
    return <Panel>
        <ComponentTitle>Bâtiments</ComponentTitle>
        <BuildingSelector 
            buildings={buildings}
            selected={selected}
            onSelect={setSelected}
        />
        {(buildings.length == 0) && <TxtMuted>Aucun bâtiment construit...</TxtMuted>}
        <FormAction>
            {selected && <Btn onClick={() => handleDelete(selected)}>Détruire</Btn>}
        </FormAction>
    </Panel>
}

interface BuildingPanelProps {
    models: BuildingModelInterface[];
    openSlots: number;
    buildingSlots: number;
    onSubmit?: (data:any) => Promise<any>;
    submitting: boolean;
}

function BuildingPanel({ models, openSlots, buildingSlots, onSubmit, submitting }: BuildingPanelProps) {
    const [formData, setFormData] = useState({
        modelId: "",
    });
    const [error, setError] = useState<string>();

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (!!onSubmit) onSubmit(formData).catch(
            (error) => setError(error.message)
            );
    };

    if (models.length == 0) return (<Panel>
        <ComponentTitle>Construire</ComponentTitle>
        <TxtMuted>Aucun bâtiment constructible...</TxtMuted>
    </Panel>);

    return (
        <Panel>
            <ComponentTitle>Construire</ComponentTitle>
            <Txt><Important>{openSlots} </Important>emplacements disponibles sur <Important>{buildingSlots}</Important></Txt>
                <Form onSubmit={handleSubmit}>
                    {error && <TxtError>{error}</TxtError>}
                    <BuildingModelSelector 
                        buildingModels={models}
                        onSelect={(modelId: string) => {
                            setFormData({ ...formData, modelId: modelId })
                        }}
                        selected={formData.modelId}
                        />
                    <FormAction submitting={submitting}>
                        <Btn type="submit">
                        Construire
                    </Btn>
                    </FormAction>
                    
                </Form>
        </Panel>
    );
}

interface RecruitingPanelProps {
    recruitCost: number;
    commanders: CommanderInterface[];
    models: UnitModelInterface[];
    onSubmit?: (data:any) => Promise<any>;
    submitting: boolean;
}

function RecruitingPanel({ recruitCost, commanders, models, onSubmit, submitting }: RecruitingPanelProps) {
    const [formData, setFormData] = useState({
        commanderId: "",
        modelId: "",
    });

    const [formErrors, setFormErrors] = useState<string[]>([]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        
        const errors = [];
        if(formData.commanderId === "") errors.push("Sélectionnez un commandant");
        if(formData.modelId === "") errors.push("Sélectionnez une unité");
        setFormErrors(errors);

        if (errors.length === 0 && !!onSubmit) onSubmit(formData).catch(
            (error: any) => setFormErrors((errors) => [...errors, error.response.data.message ?? "Erreur inconnue"])
            );
    };

    if(models.length == 0) return <Panel>
        <ComponentTitle>Recruter</ComponentTitle>
        <TxtMuted>Aucune troupe recrutable</TxtMuted>
    </Panel>

    return (
        <Panel>
            <ComponentTitle>Recruter</ComponentTitle>
            <TxtMuted>Recruter coûte {recruitCost} PA au recruteur</TxtMuted>
            <TxtMuted>Le charisme d'un commandant détermine le charisme total des soldats qui peuvent le suivre</TxtMuted>
            <Form onSubmit={handleSubmit}>
            {formErrors.map((error) => <TxtError>{error}</TxtError>) }
                <CommanderSelector
                    commanders={commanders}
                    selected={formData.commanderId}
                    onSelect={(commanderId: string) => {
                        setFormData({ ...formData, commanderId: commanderId })
                    }}
                />
                <UnitModelSelector 
                    unitModels={models}
                    selected={formData.modelId}
                    onSelect={(id: string) => {
                        setFormData({ ...formData, modelId: id })
                    }}
                    />
            <FormAction submitting={submitting}>
                <Btn type="submit">
                    Recruter ({recruitCost} PA)
                </Btn>
            </FormAction>
            </Form>    
        </Panel>
    );
}