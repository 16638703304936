import { BuildingInterface, BuildingModelInterface } from "game/services/BuildingService/BuildingServiceInterface";
import { CityInterface } from "game/services/CityService/CityServiceInterface";
import { CommanderInterface, CommanderModelInterface, UnitInterface, UnitModelInterface } from "game/services/CommanderService/CommanderServiceInterface";
import { NationInterface } from "game/services/NationService/NationServiceInterface";

interface IconProps {
    src: string;
    alt: string;
    selected?: boolean;
}

export default function Icon({ src, alt, selected }: IconProps) {
    return <img
        src={src.toLowerCase()}
        className={
            "icon" + selected ? " selected" : ""
        }

        alt={alt}
    />
}

interface BuildingModelIconProps {
    model: BuildingModelInterface;
    selected?: boolean;
}

export function BuildingModelIcon({ model, selected }: BuildingModelIconProps) {
    return <Icon
        src={"/rsc/buildings/" + model.id + ".png"}
        alt={model.name}
        selected={selected} />
}

interface BuildingIconProps {
    building: BuildingInterface;
    selected?: boolean;
}

export function BuildingIcon({ building, selected }: BuildingIconProps) {
    return <BuildingModelIcon model={building.model} selected={selected} />
}

interface CityIconProps  {
    city: CityInterface;
    selected?: boolean
}

export function CityIcon({city, selected}: CityIconProps){
    return <Icon
        src={'/rsc/cities/'+ (city.owner?.id ?? 'neutral') +'.png'}
        alt={city.name}
        selected={selected}
        />
}


interface CommanderModelIconProps {
    model: CommanderModelInterface | string;
    alt?: string;
    selected?: boolean;
}

export function CommanderModelIcon({ model, alt, selected }: CommanderModelIconProps) {
    if (typeof model === 'string' || model instanceof String) {
        return <Icon
            src={"/rsc/commanders/" + model + ".png"}
            alt={alt ?? String(model)}
            selected={selected}
        />;
    }
    return <Icon
        src={"/rsc/commanders/" + model.id + ".png"}
        alt={alt ?? model.name}
        selected={selected}
    />
}

interface CommanderIconProps {
    commander: CommanderInterface;
    selected?: boolean;
}

export function CommanderIcon({ commander, selected }: CommanderIconProps) {
    return <CommanderModelIcon
        model={commander.model}
        alt={commander.name}
        selected={selected}
    />
}

interface NationIconProps {
    nation: NationInterface;
    selected?: boolean;
}

export function NationIcon({ nation, selected }: NationIconProps) {
    return <Icon
        src={"/rsc/nations/icons/" + nation.id + ".png"}
        alt={nation.name}
        selected={selected}
    />
}

interface UnitModelIconProps {
    model: UnitModelInterface | string;
    selected?: boolean;
}

export function UnitModelIcon({ model, selected }: UnitModelIconProps) {
    if (typeof model === 'string' || model instanceof String) {
        return <Icon
            src={"/rsc/units/" + model + ".png"}
            alt={String(model)}
            selected={selected}
        />;
    }

    return <Icon
        src={"/rsc/units/" + model.id + ".png"}
        alt={model.name}
        selected={selected} />
}

interface UnitIconProps {
    unit: UnitInterface;
    selected?: boolean;
}

export function UnitIcon({ unit, selected }: UnitIconProps) {
    return <UnitModelIcon model={unit.model} selected={selected} />
}