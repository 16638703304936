import { useEffect, useState } from "react";
import EventHub from "shared/services/Observer/EventHub";

interface EntityInterface {
    id: string|number;
}



export default function useGameEntities<T extends EntityInterface>(name: string, init: T[], hub: EventHub){
    const [entities, setEntities] = useState<T[]>(init);

    const addEntity = (created: T) => {
        setEntities((entities) => [...entities, created]);
    }

    const updateEntity = (updated: T | null) => {
        if(updated) updateEntities([updated]);
    }

    const updateEntities = (updated: T[]) => {
        setEntities((entities) => entities.reduce(
            (carry, item) => carry.find((entity) => entity.id === item.id) ? carry : [...carry, item],
            updated
          ))
    }

    const deleteEntity= (id: string|number) => {
        setEntities((entities) => entities.filter((entity) => entity.id === id));
    }

    useEffect(() => {
        hub.on(name+':created', addEntity);
        hub.on(name+':updated', updateEntity);
        hub.on(name+':deleted', deleteEntity);
        return () => {
            hub.off(name+':created', addEntity);
            hub.off(name+':updated', updateEntity);
            hub.off(name+':deleted', deleteEntity);
        }
    })

    return [entities, updateEntities] as const;
}